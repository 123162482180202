export function truncateString(s: string, length: number): string {
  if (s.length <= length) {
      return s;
  }
  return s.substring(0, length) + "...";
}

export function formatDate(date: Date): string {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  const paddedMonth = month < 10 ? `0${month}` : month;
  const paddedDay = day < 10 ? `0${day}` : day;

  return `${year}-${paddedMonth}-${paddedDay}`;
}
