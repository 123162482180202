import React, { ReactElement, useEffect, useState } from "react";
import { BaseProps } from "../../Models/Props";
import { createUseStyles } from "react-jss";
import { Button, Divider, Skeleton, TextField } from "@mui/material";
import { Theme } from "../../Styling/Theme";
import { GET_FOLDERS, SET_INCIDENT } from "../../Redux/Actions";
import { getFolders, getInLineOperationInProgress } from "../../Redux/Selectors";
import { useSelector } from "react-redux";
import Folder from "../../Assets/folder.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const useStyles = createUseStyles((theme: Theme) => {
	return {
		wrapper: {
			display: "flex",
			flexDirection: "column",
			height: "100%",
			width: "100%",
			overflowY: "hidden",
		},
		headerWrapper: {
			position: "sticky",
			top: 0,
			backgroundColor: theme.backgroundThird,
			zIndex: 1,
			width: "100%",
			paddingBottom: 10,
		},
		header: {
			fontSize: "16px",
			fontWeight: "bold",
		},
		innerWrapper: {
			padding: "10px 40px",
			"@media (max-width: 500px)": {
				padding: "10px 10px",
			},
		},
		divider: {
			borderColor: theme.dividerBorder,
			backgroundColor: theme.dividerColor,
		},
		folderWrapper: {
			display: "grid",
			gridTemplateColumns: "repeat(5, 1fr)",
			gridGap: 10,
			"@media (max-width: 1300px)": {
				gridTemplateColumns: "repeat(4, 1fr)",
			},
			"@media (max-width: 1100px)": {
				gridTemplateColumns: "repeat(3, 1fr)",
			},
			"@media (max-width: 900px)": {
				gridTemplateColumns: "repeat(2, 1fr)",
			},
			"@media (max-width: 450px)": {
				paddingTop: 20,
			},
		},
		folder: {
			display: "flex",
			position: "relative",
			flexDirection: "column",
			cursor: "pointer",
			padding: "0 10px",
			"&:hover": {
				"& img": {
					transform: "scale(1.1)",
					transition: "transform 0.2s",
				},
				"& p": {
					transform: "translateY(10px)",
					transition: "transform 0.2s",
				},
				"& span": {
					transform: "translateY(10px)",
					transition: "transform 0.2s",
				},
			},
			"& img": {
				width: "100%",
			},
			"& span": {
				marginLeft: "10%",
				marginTop: "-17%",
				fontSize: 14,
				color: theme.textSecondary,
				textAlign: "left",
			},
		},
		folderSkeleton: {
			display: "flex",
			position: "relative",
			flexDirection: "column",
			alignItems: "center",
			cursor: "pointer",
			padding: "0 5px",
			width: "100%",
		},
		folderIncidentName: {
			fontSize: 16,
			textAlign: "left",
			marginLeft: "10%",
			marginTop: 0,
		},
		folderDateName: {
			fontSize: 16,
			textAlign: "center",
			marginTop: 20,
		},
		folderHeader: {
			display: "flex",
			flexDirection: "row",
			position: "relative",
		},
		count: {
			marginLeft: "auto",
			marginRight: "auto",
			position: "absolute",
			left: "50%",
			transform: "translate(-50%, 0)",
		},
		path: {
			marginLeft: "auto",
		},
		search: {
			marginTop: 5,
			marginLeft: "auto",
		},
		backButton: {
			marginTop: 5,
			marginRight: 10,
		},
		folderLoader: {
			position: "absolute",
			top: 20,
			width: "80%",
			height: "63%",
			left: "50%",
			transform: "translate(-50%, 0)",
			borderRadius: 7,
		},
		skeletonContainer: {
			height: 0,
			width: "100%",
			overflow: "hidden",
			paddingTop: "100%",
			position: "relative"
		},
		labelLoader: {
			width: "50%",
			position: "absolute",
			top: "calc(25px + 75%)",
			left: "50%",
			height: 15,
			transform: "translate(-50%, 0)",
		},
		searchButton: {
			"@media (max-width: 620px)": {
				width: 150,
			},
		},
		amountOfFoldersText: {
			"@media (max-width: 500px)": {
				paddingTop: 40,
			},
		},
		amountOfFoldersTextSkeleton: {
			"@media (max-width: 500px)": {
				display: "none",
			},
		},
		amountOfFoldersTextSkeletonSecond: {
			"@media (min-width: 500px)": {
				display: "none",
			},
		},
	}
});

export const FileExplorer: React.FC<BaseProps> = ({ dispatch }): JSX.Element => {
	const [folderSearch, setFolderSearch] = React.useState({ month: "", year: "" } as { month: string, year: string });
	const [data, setData] = React.useState([] as string[]);
	const [filter, setFilter] = React.useState("" as string);

	const folders = useSelector(getFolders);
	const inLineOperationInProgress = useSelector(getInLineOperationInProgress);

	const navigate = useNavigate();

	const classes = useStyles();

	useEffect(() => {
		setFilter("");
		dispatch({ type: GET_FOLDERS, payload: folderSearch });
	}, [dispatch, folderSearch]);

	const handleFolderClick = (folder: string) => {
		if (folderSearch.year === "") {
			setFolderSearch({ ...folderSearch, year: folder });
		} else if (folderSearch.month === "") {
			setFolderSearch({ ...folderSearch, month: folder });
		} else {
			dispatch({ type: SET_INCIDENT, payload: {} });
			const splitFolder = folder.split(" ");
			navigate(`/incident?id=${splitFolder.slice(0, splitFolder.length - 1).join(" ")}`);
		}
	}

	useEffect(() => {
		if (filter !== "") {
			var filteredFolders: string[] = folders.folders?.filter((folder: string) => {
				return folder.toLowerCase().includes(filter.toLowerCase());
			}) || [];
			setData(filteredFolders);
		} else {
			setData(folders.folders || []);
		}
	}, [folders, filter]);

	const [isScrolled, setIsScrolled] = useState(false);

	useEffect(() => {
		function handleScroll() {
			if (window.scrollY > 0) {
				setIsScrolled(true);
			} else {
				setIsScrolled(false);
			}
		}

		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<div className={classes.wrapper}>
			<div className={classes.innerWrapper}>
				<div className={classes.headerWrapper} style={isScrolled ? { borderBottom: "1px solid #e0e0e0" } : {}}>
					<div className={classes.folderHeader}>
						<div className={classes.header}>
							<p>File Explorer</p>
						</div>
						<div className={classes.path}>
							<p>
								{folderSearch.year !== "" && folderSearch.month !== "" ? `${folderSearch.year}/${folderSearch.month}/` : folderSearch.year !== "" ? `${folderSearch.year}/` : "/"}
							</p>
						</div>
					</div>
					<Divider className={classes.divider} />
					<div className={classes.folderHeader}>
						<div className={classes.backButton}>
							<Button
								variant="outlined"
								size="medium"
								onClick={(e) => {
									e.preventDefault();
									if (folderSearch.month !== "") {
										setFolderSearch({ ...folderSearch, month: "" });
									} else if (folderSearch.year !== "") {
										setFolderSearch({ ...folderSearch, year: "" });
									}
								}}
								startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
							>
								Back
							</Button>
						</div>
						<div className={classes.count}>
							{inLineOperationInProgress && <Skeleton variant="text" sx={{ marginTop: 2 }} className={classes.amountOfFoldersTextSkeleton} width={80} height={25}/>}
							{inLineOperationInProgress && <Skeleton variant="text" sx={{ marginTop: 6 }} className={classes.amountOfFoldersTextSkeletonSecond} width={80} height={25}/>}
							{!inLineOperationInProgress && <p className={classes.amountOfFoldersText}>{data.length} Folders</p>}
						</div>
						<div className={classes.search}>
							<TextField
								label="Search"
								variant="outlined"
								size="small"
								onChange={(e) => {
									setFilter(e.target.value);
								}}
								className={classes.searchButton}
							/>
						</div>
					</div>
				</div>
				<div className={classes.folderWrapper}>
					{!inLineOperationInProgress && data.map((folder, index) => {
						return (
							<div key={index} className={classes.folder}
								onClick={(e) => {
									e.preventDefault();
									handleFolderClick(folder);
								}}
							>
								<img src={Folder} alt="Folder" />
								<span>{getIncidentDate(folder)}</span>
								{getIncidentName(folder, classes)}
							</div>
						);
					})}
					{inLineOperationInProgress && 
						Array.from(Array(data.length ? data.length : 5)).map((_, index) => {
							return (
								<div key={index} className={classes.folderSkeleton}>
									<div className={classes.skeletonContainer}>
										<Skeleton
											variant="rectangular"
											animation="wave"
											className={classes.folderLoader}
											sx={{ marginTop: 3 }}
										/>
										<Skeleton
											variant="text"
											animation="wave"
											className={classes.labelLoader}
											sx={{ marginTop: 0 }}
										/>
									</div>
								</div>
							);
						})
					}
				</div>
			</div>
		</div>
	);
}

function getIncidentName(name: string, classes: any): ReactElement<any, any> {
	const splitFolder = name.split(" ");
	if (splitFolder.length <= 1) {
		return <p className={classes.folderDateName}>{name}</p>;
	}

	return <p className={classes.folderIncidentName}>{splitFolder.slice(0, splitFolder.length - 1).join(" ")}</p>
}

function getIncidentDate(name: string): string {
	const splitFolder = name.split(" ");
	if (splitFolder.length <= 1) {
		return "";
	}

	const dateStr = splitFolder[splitFolder.length - 1];

	// match date in yyyy-mm-dd-hh format
	const match1 = dateStr.match(/(\d{4})-(\d{1,2})-(\d{1,2})-(\d{1,2})/);
	let date: Date | null = null;

	if (match1) {
		const year = parseInt(match1[1]);
		const month = parseInt(match1[2]) - 1; // JS months are 0-based
		const day = parseInt(match1[3]);
		const hour = parseInt(match1[4]);
		date = new Date(year, month, day, hour);
	} else {
		// match date in yyyymmdd_hhmm format
		const match2 = dateStr.match(/(\d{4})(\d{2})(\d{2})_(\d{2})(\d{2})/);

		if (match2) {
			const year = parseInt(match2[1]);
			const month = parseInt(match2[2]) - 1; // JS months are 0-based
			const day = parseInt(match2[3]);
			const hour = parseInt(match2[4]);
			const minute = parseInt(match2[5]);
			date = new Date(year, month, day, hour, minute);
		}
	}

	if (date) {
		const dateString = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
		const timeString = `${(date.getHours() % 12 === 0 ? 12 : date.getHours() % 12)}:${date.getMinutes().toString().padStart(2, '0')}${(date.getHours() >= 12 ? ' PM' : ' AM')}`;
		return `${dateString} ${timeString}`;
	}

	return dateStr;
}
