import React, { useEffect } from "react";
import { Sidebar as ProSidebar, Menu, MenuItem, useProSidebar, MenuItemStyles } from 'react-pro-sidebar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom';
import { faChartBar, faFile } from '@fortawesome/free-solid-svg-icons'
import { SidebarHeader } from './Components/SidebarHeader';
import { SidebarFooter } from './Components/SidebarFooter';
import { Theme } from "../../Styling/Theme";
import { Divider } from "@mui/material";
import { createUseStyles } from "react-jss";
import { useLocation } from 'react-router-dom';
import { AppDispatch } from "../../store";
import { MobileNavBar } from "./Components/Mobile";

const useStyles = createUseStyles((theme: Theme) => {
  return {
    sidebar: {
      border: '1px solid' + theme.sidebarBorder,
    },
    wrapper: {
      display: 'flex',
      height: '100vh',
      position: 'fixed',
      width: "fit-content",
      zIndex: 100,
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    menuDivider: {
      flex: 1,
      marginBottom: 32,
    },
    divider: {
			borderColor: theme.dividerBorder,
			backgroundColor: theme.dividerColor,
		},
    activeTab: {
      backgroundColor: theme.backgroundSecondary,
      color: theme.textPrimary,
    },
    spacing: {
      marginBottom: 10,
    },
    root: {
      width: 'fit-content',
    },
  }
})

interface SidebarProps {
  setTheme: (darkMode: boolean) => void;
  darkMode: boolean;
  theme: Theme;
  dispatch: AppDispatch
  setCollapsed: (collapse: boolean) => void;
}

export const Sidebar: React.FC<SidebarProps> = ({ setTheme, darkMode, theme, dispatch, setCollapsed }): JSX.Element => {
	const { collapseSidebar, collapsed } = useProSidebar();
  const [currentPath, setCurrentPath] = React.useState(window.location.pathname);

  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    if (localStorage['collapseSidebar'] == null) localStorage['collapseSidebar'] = 'false';

    collapseSidebar(localStorage['collapseSidebar'] === 'true');
  }, [collapseSidebar]);

  useEffect(() => {
    setCollapsed(collapsed);
    // localStorage['collapseSidebar'] = collapsed.toString();
  }, [collapsed, setCollapsed]);

  const menuItemStyles: MenuItemStyles = {
    button: {
      '&:hover': {
        backgroundColor: theme.backgroundSecondary,
        color: theme.textPrimary,
      },
      '&:active': {
        backgroundColor: theme.backgroundSecondary,
        color: theme.textPrimary,
      },
    },
  };

  useEffect(() => {
    setCurrentPath(window.location.pathname);
  }, [location]);

  return (
    <>
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <ProSidebar
          backgroundColor={theme.backgroundPrimary}
          rootStyles={{ color: theme.textPrimary, border: darkMode ? '1px solid #0B2948' : '1px solid #e0e0e0', borderTop: 'none', borderLeft: 'none' }}
          width="270px"
          collapsedWidth="80px"
          breakPoint="md"
        >
          <div className={classes.content}>
            <SidebarHeader collapsed={collapsed} />
            <div className={classes.menuDivider}>
              <Divider variant="middle" className={classes.divider} />
              <div className={classes.spacing} />
              <Menu menuItemStyles={menuItemStyles}>
                <MenuItem component={<Link to="/incidents" />} icon={<FontAwesomeIcon icon={faChartBar} color={darkMode ? "#8FC9F9" : "#1876D1"} />}
                  className={currentPath === '/incidents' ? classes.activeTab : ''}
                >
                  Data Explorer
                </MenuItem>
                <MenuItem component={<Link to="/file-explorer" />} icon={<FontAwesomeIcon icon={faFile} color={darkMode ? "#8FC9F9" : "#1876D1"} />}
                  className={currentPath === '/file-explorer' ? classes.activeTab : ''}
                >
                  File Explorer
                </MenuItem>
              </Menu>
            </div>
            <SidebarFooter
              collapsed={collapsed}
              setCollapsed={(collapse: boolean) => {
                collapseSidebar(collapse);
                localStorage['collapseSidebar'] = collapse;
              }}
              setDarkMode={setTheme}
              darkMode={darkMode}
              dispatch={dispatch}
            />
          </div>
        </ProSidebar>
      </div>    
    </div>
    <MobileNavBar
      currentPath={currentPath}
      setTheme={setTheme}
      darkMode={darkMode}
      dispatch={dispatch}
      theme={theme}
    />
    </>
  );
};