import { DispatchProps } from "../../../../Models/Props";
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { Button, FormControl, InputLabel, Menu, MenuItem, Select } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import { createUseStyles } from "react-jss";
import { Theme } from "../../../../Styling/Theme";
import EventIcon from '@mui/icons-material/Event';
import ClearIcon from '@mui/icons-material/Clear';
import { useSelector } from "react-redux";
import { getDataExplorer, getIncidents } from "../../../../Redux/Selectors";
import { RESET_DATA_EXPLORER, SET_DATA_EXPLORER_CASE_ID_DESCRIPTION, SET_DATA_EXPLORER_COUNTY, SET_DATA_EXPLORER_DATE, SET_DATA_EXPLORER_DATERANGE, SET_DATA_EXPLORER_DIRECTION, SET_DATA_EXPLORER_LIMIT, SET_DATA_EXPLORER_ROADWAY } from "../../../../Redux/Actions";
import { county, direction, limits, roadway } from "./OptionsData";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotateRight, faDownload } from "@fortawesome/free-solid-svg-icons";
import * as XLSX from 'xlsx';
import toast from "react-hot-toast";
import { IncidentInformation } from "../../../../Models/DataExplorer";

const useStyles = createUseStyles((theme: Theme) => {
	return {
		options: {
			display: "grid",
			gridTemplateColumns: "repeat(2, 1fr)",
			gridGap: "1rem",
			padding: "30px 20px 50px 20px",
			"& > div": {
				width: 350,
				margin: "0 auto",
				height: 52,
			},
			"& .react-daterange-picker__calendar": {
				zIndex: 10,
			},
			"& .react-daterange-picker__inputGroup__input": {
				color: theme.textPrimary,
			},
			"& .react-daterange-picker__wrapper": {
				borderRadius: 4,
				borderColor: theme.borderMUI,
			},
			"& .react-daterange-picker__inputGroup:first-child": {
				textAlign: "right",
			},
			"@media (max-width: 1060px)": {
				gridTemplateColumns: "1fr",
			},
			"@media (max-width: 600px)": {
				"& > div": {
					width: "100%",
				},
			},
		},
		itemsPerPageMobile: {
			"@media (min-width: 900px)": {
				display: "none",
			},
		},
		itemsPerPageDesktop: {
			"@media (max-width: 900px)": {
				display: "none",
			},
		},
		buttonLeft: {
			float: "left",
			"@media (max-width: 460px)": {
				float: "none",
				width: "80%",
				margin: "5px 10%",
			},
		},
		buttonRight: {
			float: "right",
			"@media (max-width: 460px)": {
				float: "none",
				width: "80%",
				margin: "5px 10%",
			},
		},
	}
});

export const Options: React.FC<DispatchProps> = ({ dispatch }): JSX.Element => {
	const [id, setid] = useState<string>("");
	const [description, setDescription] = useState<string>("");
	const dataExplorer = useSelector(getDataExplorer);
	const incidents = useSelector(getIncidents);
	const classes = useStyles();

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			dispatch({ type: SET_DATA_EXPLORER_CASE_ID_DESCRIPTION, payload: { id, description } });
		}, 1000);
		return () => clearTimeout(timeoutId);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id, description, dispatch]);

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

	return (
			<>
			<Box className={classes.options}>
				<TextField
					label="Case ID"
					variant="outlined"
					value={id}
					onChange={(value) => {
						setid(value.target.value);
					}}
				/>
				<FormControl sx={{ minWidth: 120 }}>
					<InputLabel>Roadway</InputLabel>
					<Select
						value={dataExplorer.roadwayName}
						onChange={(value) => {
							dispatch({ type: SET_DATA_EXPLORER_ROADWAY, payload: { roadwayName: value.target.value } });
						}}
						label={"Roadway"}
					>
						{roadway.map((item, index) => {
							return <MenuItem key={index} value={item.value}>{item.display}</MenuItem>
						})}
					</Select>
				</FormControl>
				<LocalizationProvider dateAdapter={AdapterDayjs}>
					<DatePicker
						label="Date"
						value={dataExplorer.date}
						onChange={(date) => {
							dispatch({ type: SET_DATA_EXPLORER_DATE, payload: { date } });
						}}
					/>
					<DateRangePicker
						calendarIcon={<EventIcon color="action" />}
						clearIcon={<ClearIcon color="action" />}
						value={dataExplorer.dateRange}
						onChange={(value) => {
							dispatch({ type: SET_DATA_EXPLORER_DATERANGE, payload: { dateRange: value } });
						}}
					/>
				</LocalizationProvider>
			<FormControl sx={{ minWidth: 120 }}>
				<InputLabel>County</InputLabel>
				<Select
					value={dataExplorer.county}
					onChange={(value) => {
						dispatch({ type: SET_DATA_EXPLORER_COUNTY, payload: { county: value.target.value } });
					}}
					label={"County"}
				>
					{county.map((item, index) => {
						return <MenuItem key={index} value={item.value}>{item.display}</MenuItem>
					})}
				</Select>
			</FormControl>
			<FormControl sx={{ minWidth: 120 }}>
				<InputLabel>Direction</InputLabel>
				<Select
					value={dataExplorer.direction}
					onChange={(value) => {
						dispatch({ type: SET_DATA_EXPLORER_DIRECTION, payload: { direction: value.target.value } });
					}}
					label={"Direction"}
				>
					{direction.map((item, index) => {
						return <MenuItem key={index} value={item.value}>{item.display}</MenuItem>
					})}
				</Select>
			</FormControl>
			<TextField
				label="Description Contains..."
				multiline
				variant="outlined"
				value={description}
				onChange={(value) => {
					setDescription(value.target.value);
				}}
			/>
			<FormControl className={classes.itemsPerPageMobile}>
				<InputLabel>Items Per Page</InputLabel>
				<Select
					value={dataExplorer.limit}
					onChange={(value) => {
						dispatch({ type: SET_DATA_EXPLORER_LIMIT, payload: { limit: value.target.value } });
					}}
					label={"Items Per Page"}
				>
					{limits.map((item, index) => {
						return <MenuItem key={index} value={item}>{item}</MenuItem>
					})}
				</Select>
			</FormControl>		
		</Box>
		<Button
			variant="contained"
			onClick={() => {
				dispatch({ type: RESET_DATA_EXPLORER, payload: {} });
				setid("");
				setDescription("");
			}}
			endIcon={<FontAwesomeIcon icon={faRotateRight} />}
			sx={{ width: 200, margin: "10px 10px"}}
			className={classes.buttonRight}
		>
			Reset Fields
		</Button>
		<Button
			variant="contained"
			
			endIcon={<FontAwesomeIcon icon={faDownload} />}
			sx={{ width: 160, margin: "10px 10px" }}
			id="demo-positioned-button"
			aria-controls={open ? 'demo-positioned-menu' : undefined}
			aria-haspopup="true"
			aria-expanded={open ? 'true' : undefined}
			onClick={handleClick}
			className={classes.buttonLeft}
		>
			Export
		</Button>
		<Menu
			id="demo-positioned-menu"
			aria-labelledby="demo-positioned-button"
			anchorEl={anchorEl}
			open={open}
			onClose={handleClose}
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'left',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'left',
			}}
			sx={{ width: 235 }}
		>
			<MenuItem
				onClick={() => {
					exportToCSV(
						incidents
					)
					handleClose()
				}}
				sx={{ width: 250 }}
			>
				CSV
			</MenuItem>
			<MenuItem
				onClick={() => {
					exportToXLSX(
						incidents
					)
					handleClose()
				}}
				sx={{ width: 250 }}
			>
				Excel (xlsx)
			</MenuItem>
		</Menu>
		<FormControl sx={{ minWidth: 150, float: "left" }} className={classes.itemsPerPageDesktop}>
			<InputLabel>Items Per Page</InputLabel>
			<Select
				value={dataExplorer.limit}
				onChange={(value) => {
					dispatch({ type: SET_DATA_EXPLORER_LIMIT, payload: { limit: value.target.value } });
				}}
				label={"Items Per Page"}
				sx={{ height: 40, marginTop: 1 }}
			>
				{limits.map((item, index) => {
					return <MenuItem key={index} value={item}>{item}</MenuItem>
				})}
			</Select>
		</FormControl>
		</>
	);
};

function exportToCSV(incidents: IncidentInformation[]) {
	try {
		const rows = incidents.map((incident) => {
			const incidents = {
				id: incident.id,
				county: incident.county,
				description: incident.description,
				direction: incident.direction,
				lastUpdated: incident.lastUpdated,
				region: incident.region,
				roadway: incident.roadwayName,
				severity: incident.severity,
				startTime: incident.startTime,
			}
			return Object.values(incidents).join(",");
		}).join("\n");
		const headers = [
			"id",
			"county",
			"description",
			"direction",
			"lastUpdated",
			"region",
			"roadway",
			"severity",
			"startTime",
		];

		const csvContent = [headers.join(","), rows].join("\n");
	
		const csvContentData = "data:text/csv;charset=utf-8," + csvContent;
		const encodedUri = encodeURI(csvContentData);
		const link = document.createElement("a");
		link.setAttribute("href", encodedUri);
		link.setAttribute("download", "incidents.csv");
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
		toast.success("Downloaded to CSV")
	} catch {
		toast.error("Unable to download to CSV")
	}
	
}

function exportToXLSX(incidents: IncidentInformation[]) {
	try {
		const rows = incidents.map((incident) => {
			return {
				id: incident.id,
				county: incident.county,
				description: incident.description,
				direction: incident.direction,
				lastUpdated: incident.lastUpdated,
				region: incident.region,
				roadway: incident.roadwayName,
				severity: incident.severity,
				startTime: incident.startTime,
			};
		});
		const headers = [
			"id",
			"county",
			"description",
			"direction",
			"lastUpdated",
			"region",
			"roadway",
			"severity",
			"startTime",
		];
		const sheet = XLSX.utils.json_to_sheet(rows, { header: headers });
		const workbook = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(workbook, sheet, "Incidents");
		const filename = "incidents.xlsx";
		XLSX.writeFile(workbook, filename);
		toast.success("Downloaded to Excel")
	} catch {
		toast.error("Unable to download to excel")
	}
}