import { Camera } from "../Models/Camera";
import { DataExplorer, IncidentInformation } from "../Models/DataExplorer";
import { Folders } from "../Models/Folder";
import { IncidentMapInput, IncidentSpecific } from "../Models/Incident";
import { User } from "../Models/User";
import { SET_OPERATION_IN_PROGRESS, SET_IN_LINE_OPERATION_IN_PROGRESS, SET_USER, SET_FOLDERS, SET_INCIDENT, SET_DATA_EXPLORER, SET_INCIDENTS, SET_DATA_EXPLORER_id, SET_DATA_EXPLORER_ROADWAY, SET_DATA_EXPLORER_DATE, SET_DATA_EXPLORER_DATERANGE, SET_DATA_EXPLORER_COUNTY, SET_DATA_EXPLORER_SEVERITY, SET_DATA_EXPLORER_DIRECTION, SET_DATA_EXPLORER_DESCRIPTION, SET_DATA_EXPLORER_LIMIT, SET_DATA_EXPLORER_OFFSET, SET_DATA_EXPLORER_PAGE, SET_DATA_EXPLORER_SORT, SET_DATA_EXPLORER_ORDER, SET_DATA_EXPLORER_PAGECOUNT, SET_DATA_EXPLORER_COUNT, SET_DATA_EXPLORER_ORDER_SORT, SET_DATA_EXPLORER_CASE_ID_DESCRIPTION, RESET_DATA_EXPLORER, SET_DATA_EXPLORER_OFFSET_PAGE, SET_DATA_EXPLORER_SELECTED_ROWS, SET_CONFIRMATION_CODE, SET_CONFIRM_NEW_PASSWORD, SET_EMAIL, SET_NEW_PASSWORD, SET_CONFIRMATION_CODE_SENT, SET_LOGIN_TAB, SET_DATE_BUFFER, SET_COORDINATE_BUFFER, SET_INCIDENT_ADDITIONAL, SET_IN_IN_LINE_OPERATION_IN_PROGRESS, SET_CAMERA_LOGS, SET_CAMERA_CAMERAID, SET_CAMERA_DATE, SET_CAMERA } from "./Actions";

export interface State {
	operationInProgress: boolean;
	inLineOperationInProgress: boolean;
	inInLineOperationInProgress: boolean;
	user: User;
	folders: Folders;
	incidents: IncidentInformation[];
	incident: IncidentSpecific;
	dataExplorer: DataExplorer;
	incidentMapInput: IncidentMapInput
	loginPageTab: "login" | "register" | "forgot";
	camera: Camera;
}

export const initialState: State = {
	operationInProgress: true,
	inLineOperationInProgress: false,
	inInLineOperationInProgress: false,
	user: {
		loaded: false,
		forgotPassword: {
			email: '',
			confirmationCode: '',
			newPassword: '',
			confirmNewPassword: '',
			confirmationCodeSent: false,
		},
	} as User,
	folders: {} as Folders,
	incidents: [],
	incident: {} as IncidentSpecific,
	dataExplorer: {
		id: '',
		roadwayName: '',
		date: null,
		dateRange: [null, null],
		county: '',
		severity: '',
		direction: '',
		description: '',
		limit: 10,
		offset: 0,
		page: 1,
		sort: '',
		order: 'asc',
		pageCount: 0,
		count: 0,
		selectedRows: [],
	},
	incidentMapInput: {
		dateBuffer: 7,
		coordinateBuffer: 10,
	},
	loginPageTab: "login",
	camera: {
		information: {},
		unfilteredInformation: {},
		cameraId: '',
		date: '',
	},
};

export default function Reducer(state = initialState, { type, payload }: { type: string; payload: any }): State {
	console.debug("Reducer", type, payload);
	
	switch (type) {
		case SET_OPERATION_IN_PROGRESS:
			return {
				...state,
				operationInProgress: payload,
			};
		case SET_IN_LINE_OPERATION_IN_PROGRESS:
			return {
				...state,
				inLineOperationInProgress: payload,
			};
		case SET_USER:
			return {
				...state,
				user: {
					...payload,
					forgotPassword: initialState.user.forgotPassword,
				},
			};
		case SET_FOLDERS:
			return {
				...state,
				folders: payload,
			};
		case SET_INCIDENT:
			return {
				...state,
				incident: payload,
			};
		case SET_DATA_EXPLORER:
			return {
				...state,
				dataExplorer: payload,
			};
		case SET_INCIDENTS:
			return {
				...state,
				incidents: payload,
			};
		case SET_DATA_EXPLORER_id:
			return {
				...state,
				dataExplorer: {
					...state.dataExplorer,
					id: payload.id,
				}
			};
		case SET_DATA_EXPLORER_ROADWAY:
			return {
				...state,
				dataExplorer: {
					...state.dataExplorer,
					roadwayName: payload.roadwayName,
				}
			};
		case SET_DATA_EXPLORER_DATE:
			return {
				...state,
				dataExplorer: {
					...state.dataExplorer,
					date: payload.date,
					dateRange: [null, null]
				}
			};
		case SET_DATA_EXPLORER_DATERANGE:
			return {
				...state,
				dataExplorer: {
							...state.dataExplorer,
							dateRange: payload.dateRange,
							date: null
					}
			};
		case SET_DATA_EXPLORER_COUNTY:
			return {
				...state,
				dataExplorer: {
					...state.dataExplorer,
					county: payload.county,
				}
			};
		case SET_DATA_EXPLORER_SEVERITY:
			return {
				...state,
				dataExplorer: {
					...state.dataExplorer,
					severity: payload.severity,
				}
			};
		case SET_DATA_EXPLORER_DIRECTION:
			return {
				...state,
				dataExplorer: {
					...state.dataExplorer,
					direction: payload.direction,
				}
			};
		case SET_DATA_EXPLORER_DESCRIPTION:
			return {
				...state,
				dataExplorer: {
					...state.dataExplorer,
					description: payload.description,
				}
			};
		case SET_DATA_EXPLORER_LIMIT:
			return {
				...state,
				dataExplorer: {
					...state.dataExplorer,
					limit: payload.limit,
				}
			};
		case SET_DATA_EXPLORER_OFFSET:
			return {
				...state,
				dataExplorer: {
					...state.dataExplorer,
					offset: payload.offset,
				}
			};
		case SET_DATA_EXPLORER_PAGE:
			return {
				...state,
				dataExplorer: {
					...state.dataExplorer,
					page: payload.page,
				}
			};
		case SET_DATA_EXPLORER_SORT:
			return {
				...state,
				dataExplorer: {
					...state.dataExplorer,
					sort: payload.sort,
				}
			};
		case SET_DATA_EXPLORER_ORDER:
			return {
				...state,
				dataExplorer: {
					...state.dataExplorer,
					order: payload.order,
				}
			};
		case SET_DATA_EXPLORER_PAGECOUNT:
			return {
				...state,
				dataExplorer: {
					...state.dataExplorer,
					pageCount: payload.pageCount,
				}
			};
		case SET_DATA_EXPLORER_COUNT:
			return {
				...state,
				dataExplorer: {
					...state.dataExplorer,
					count: payload.count,
				}
			};
		case SET_DATA_EXPLORER_ORDER_SORT:
			return {
				...state,
				dataExplorer: {
					...state.dataExplorer,
					order: payload.order,
					sort: payload.sort,
				}
			};
		case SET_DATA_EXPLORER_CASE_ID_DESCRIPTION:
			return {
				...state,
				dataExplorer: {
					...state.dataExplorer,
					id: payload.id,
					description: payload.description,
				}
			};
		case RESET_DATA_EXPLORER:
			return {
				...state,
				dataExplorer: initialState.dataExplorer,
			};
		case SET_DATA_EXPLORER_OFFSET_PAGE:
			return {
				...state,
				dataExplorer: {
					...state.dataExplorer,
					offset: payload.offset,
					page: payload.page,
				}
			};
		case SET_DATA_EXPLORER_SELECTED_ROWS:
			return {
				...state,
				dataExplorer: {
					...state.dataExplorer,
					selectedRows: payload.selectedRows
				}
			}
		case SET_CONFIRMATION_CODE:
			return {
				...state,
				user: {
					...state.user,
					forgotPassword: {
						...state.user.forgotPassword,
						confirmationCode: payload,
					}
				}
			};
		case SET_EMAIL:
			return {
				...state,
				user: {
					...state.user,
					forgotPassword: {
						...state.user.forgotPassword,
						email: payload,
					}
				}
			};
		case SET_NEW_PASSWORD:
			return {
				...state,
				user: {
					...state.user,
					forgotPassword: {
						...state.user.forgotPassword,
						newPassword: payload,
					}
				}
			};
		case SET_CONFIRM_NEW_PASSWORD:
			return {
				...state,
				user: {
					...state.user,
					forgotPassword: {
						...state.user.forgotPassword,
						confirmNewPassword: payload,
					}
				}
			};
		case SET_CONFIRMATION_CODE_SENT:
			return {
				...state,
				user: {
					...state.user,
					forgotPassword: {
						...state.user.forgotPassword,
						confirmationCodeSent: payload,
					}
				}
			};
		case SET_LOGIN_TAB:
			return {
				...state,
				loginPageTab: payload,
			};
		case SET_DATE_BUFFER:
			return {
				...state,
				incidentMapInput: {
					...state.incidentMapInput,
					dateBuffer: payload,
				}
			};
		case SET_COORDINATE_BUFFER:
			return {
				...state,
				incidentMapInput: {
					...state.incidentMapInput,
					coordinateBuffer: payload,
				}
			};
		case SET_INCIDENT_ADDITIONAL:
			return {
				...state,
				incident: {
					...state.incident,
					cameraInformation: payload.cameraInformation,
					events: payload.events,
				}
			};
		case SET_IN_IN_LINE_OPERATION_IN_PROGRESS:
			return {
				...state,
				inInLineOperationInProgress: payload,
			};
		case SET_CAMERA_LOGS:
			return {
				...state,
				camera: {
					...state.camera,
					information: payload.information,
					unfilteredInformation: payload.unfilteredInformation,
				}
			};
		case SET_CAMERA_CAMERAID:
			return {
				...state,
				camera: {
					...state.camera,
					cameraId: payload,
				}
			};
		case SET_CAMERA_DATE:
			return {
				...state,
				camera: {
					...state.camera,
					date: payload,
				}
			};
		case SET_CAMERA:
			return {
				...state,
				camera: payload,
			};
		default:
			return state;
	}
}