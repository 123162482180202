import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Sidebar } from './Pages/Sidebar/Sidebar';
import { initializeTheme, Theme } from './Styling/Theme';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from './store';
import { ThemeProvider } from 'react-jss';
import { ComponentRouting } from './ComponentRouting';
import './App.css'
import { createUseStyles } from 'react-jss';
import { getOperationInProgress, getUserSelect } from './Redux/Selectors';
import { Login } from './Pages/Login/Login';
import { GET_USER } from './Redux/Actions';
import { Toaster } from 'react-hot-toast';
import { ThemeProvider as MuiThemeProvider, createTheme } from '@mui/material/styles';
import { Backdrop, CircularProgress } from '@mui/material';

const useStyles = createUseStyles((theme: Theme) => {
  return {
    section: {
      display: 'flex',
      height: '100%',
      minHeight: '100vh',
    }
  }
})

export const App = (): JSX.Element => {
  if (localStorage['darkMode'] == null) localStorage['darkMode'] = 'false';

  const [darkMode, setDarkMode] = React.useState<boolean>(localStorage['darkMode'] === 'true');
  const [theme, setTheme] = React.useState<Theme>(initializeTheme(darkMode));
  const [collapsed, setCollapsed] = React.useState<boolean>(false);

  const user = useSelector(getUserSelect);
  const operationInProgress = useSelector(getOperationInProgress);

  const dispatch: AppDispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();

  useEffect(() => {
		dispatch({ type: GET_USER, payload: null });
	}, [dispatch]);

  useEffect(() => {
    if (user.loaded && !user.attributes) {
      navigate('/login');
    } 
    else if (user.loaded && user.attributes && window.location.pathname === '/login') {
      navigate('/incidents');
    } else if (user.loaded && user.attributes && window.location.pathname === '/') {
      navigate('/incidents');
    }
  }, [user, navigate]);

  const MuiTheme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: darkMode ? 'dark' : 'light',
          background: {
            default: theme.backgroundPrimary,
            paper: theme.backgroundPrimary,
          },
        },
      }),
    [darkMode, theme.backgroundPrimary],
  );

  useEffect(() => {
    document.body.style.backgroundColor = theme.backgroundThird;
  }, [theme.backgroundThird]);

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.section}>
      <Toaster />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={operationInProgress}
      >
        <CircularProgress color="primary" size={100} />
      </Backdrop>
      {user.loaded && !user.attributes &&
        <Login dispatch={dispatch} darkMode={darkMode} />
      }
      {user.loaded && user.attributes && 
        <MuiThemeProvider theme={MuiTheme}>
          <Sidebar
            setTheme={(darkMode: boolean) => {
              setDarkMode(darkMode);
              setTheme(initializeTheme(darkMode));
              localStorage['darkMode'] = darkMode;
            }}
            darkMode={darkMode}
            theme={theme}
            dispatch={dispatch}
            setCollapsed={(collapse: boolean) => {
              setCollapsed(collapse);
            }}
          />
          <div style={{ marginLeft: width < 769 ? 0 : collapsed ? 80 : 270, width: "100%", overflow: "hidden", marginBottom: width < 769 ? 56 : 0}}>
            <ComponentRouting
              dispatch={dispatch}
              darkMode={darkMode}
            />
          </div>
        </MuiThemeProvider>
        }
      </div>
    </ThemeProvider>
  );
}

export default App;
