import React, { useEffect } from 'react';
import { createUseStyles } from 'react-jss';
import { BaseProps } from '../../Models/Props';
import { Box, Paper, TextField } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { GET_CAMERA_LOGS, SET_CAMERA, SET_CAMERA_CAMERAID, SET_CAMERA_DATE } from '../../Redux/Actions';
import { Theme } from '../../Styling/Theme';
import { getCamera } from '../../Redux/Selectors';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

const useStyles = createUseStyles((theme: Theme) => {
  return {
    timelineContainer: {
      position: 'relative',
      width: 'calc(100% - 40px)',
      height: 'calc(100vh - 20px)',
      display: 'flex',
      flexDirection: 'column',
      margin: '10px 20px',
      overflowX: "hidden",
    },
    segmentWrapper: {
      flex: 1,
      position: 'relative',
      transform: 'scale(0.98)',
    },
    baseLine: {
      width: '100%',
      height: '1px',
      backgroundColor: 'gray',
    },
    greenLine: {
      position: 'absolute',
      height: '5px',
      backgroundColor: 'green',
      top: '10px',
    },
    redLine: {
      position: 'absolute',
      height: '5px',
      backgroundColor: 'red',
      top: '0',
    },
    timeLabel: {
      position: 'absolute',
      top: '25px',
      fontSize: '10px',
      whiteSpace: 'nowrap',
      transform: 'rotate(-30deg)',
      transformOrigin: 'top right',
      '&:before': {
        content: '""',
        position: 'absolute',
        top: '-5px',
        left: '50%',
        width: '1px',
        height: '5px',
        backgroundColor: 'gray',
        transform: 'rotate(30deg)',
      },
    },
    timeLine: {
      position: 'absolute',
      top: '20px',
      width: '100%',
      height: '1px',
      backgroundColor: 'gray',
      marginTop: '5px',
    },
    segment: {
      position: 'absolute',
      height: '20px',
      top: '0',
      '&:hover $segmentLabel': {
        visibility: 'visible',
      },
    },
    segmentLabel: {
      visibility: 'hidden',
      position: 'absolute',
      bottom: '100%',
      whiteSpace: 'nowrap',
      backgroundColor: 'white',
      padding: '5px 10px',
      border: '1px solid black',
      borderRadius: '5px',
      boxShadow: '0px 0px 5px rgba(0,0,0,0.1)',
      color: 'black',
      fontSize: '12px',
    },
    verticalLine: {
      position: 'absolute',
      width: '2px',
      height: '30px',
      backgroundColor: 'gray',
      top: '-5px',
      zIndex: 1,
    },
    errorBox: {
      position: 'absolute',
      backgroundColor: 'white',
      padding: '2px',
      border: '1px solid black',
      whiteSpace: 'nowrap',
      fontSize: '10px',
      textAlign: 'center',
      transform: 'translateX(-50%)',
      '&:hover $errorLabel': {
        visibility: 'visible',
      },
    },
    arrow: {
      position: 'absolute',
      width: '0',
      height: '0',
      borderTop: '5px solid white',
      borderLeft: '5px solid transparent',
      borderRight: '5px solid transparent',
    },
    errorLabel: {
      visibility: 'hidden',
      position: 'absolute',
      bottom: '100%',
      whiteSpace: 'nowrap',
      backgroundColor: 'white',
      padding: '2px 5px',
      border: '1px solid black',
    },
    timeLineLabel: {
      marginTop: -22,
    },
    boxLayout: {
      display: "flex",
      justifyContent: "center",
      gap: 20,
      padding: "20px 0 40px 0",
      "& > div": {
        width: 350,
        height: 52,
      },
      "& .react-daterange-picker__calendar": {
        zIndex: 10,
      },
      "& .react-daterange-picker__inputGroup__input": {
        color: theme.textPrimary,
      },
      "& .react-daterange-picker__wrapper": {
        borderRadius: 4,
        borderColor: theme.borderMUI,
      },
      "& .react-daterange-picker__inputGroup:first-child": {
        textAlign: "right",
      },
      "@media (max-width: 600px)": {
        flexDirection: "column",
        "& > div": {
          width: "100%",
        },
      },
    }
  }
});

export const Camera: React.FC<BaseProps> = ({ dispatch }): JSX.Element => {
  const camera = useSelector(getCamera);

  const classes = useStyles();

  useEffect(() => {
		window.scrollTo(0, 0);
		const urlParams = new URLSearchParams(window.location.search);
    dispatch({ type: SET_CAMERA, payload: {
      cameraId: urlParams.get("cameraId"),
      date: urlParams.get("date"),
      information: {},
      unfilteredInformation: {},
    } });
	}, [dispatch]);

  useEffect(() => {
    if (!camera.cameraId || !camera.date) return;
    dispatch({ type: GET_CAMERA_LOGS, payload: null });
  }, [dispatch, camera.cameraId, camera.date]);

  const errorMapping: { [key: number]: string } = {
    1: "HTTP error 404 Not Found",
    2: "Stopped Recording (No data received)",
    3: "Recording",
    4: "Unknown error",
    5: "End of file",
    6: "HTTP error 403 Forbidden",
    7: "HTTP error 503 Service Unavailable",
    8: "I/O error",
    9: "SEI type 5",
    10: "expired from playlists",
    12: "co-located POCs unavailable",
    13: "Non-monotonous DTS in output stream",
    14: "missing picture in access unit with size",
    15: "Manual Stop",
    16: "Exception",
    17: "Stopped due to priority change",
    18: "Unrecognized error",
    19: "Empty error",
  };

  const calculateWidthPercentage = (start: string, end: string) => {
    const startMoment = new Date(start).getTime();
    const endMoment = new Date(end).getTime();
    const oneDayInMilliseconds = 24 * 60 * 60 * 1000;

    return ((endMoment - startMoment) / oneDayInMilliseconds) * 100;
  };

  const handleHover = (e: React.MouseEvent, log: any, start: string, end: string, i: number, index: number) => {
    const segmentLabel = document.getElementById(`segmentLabel-${index}-${i}`);
    if (!segmentLabel) return;
  
    segmentLabel.style.visibility = 'visible';
  };

  const renderSegments = (logs: any, index: number) => {
    const segments = [];
    for (let i = 0; i < logs.length; i++) {
      const start = logs[i].t;
      let end: any;
      if (logs.length === 1) {
        end = new Date(new Date(start).getTime() + 1000 * 60 * 60 * 48).toISOString().replace(/T.*/, 'T00:00:00');
      } else {
        end = i < logs.length - 1 ? logs[i + 1].t : new Date(new Date(logs[logs.length - 1].t).getTime() + 1000 * 60 * 60 * 24).toISOString().replace(/T.*/, 'T00:00:00');
      }
      const width = calculateWidthPercentage(start, end);
      const color = logs[i].s === 3 ? 'green' : 'red';
  
      // Calculate the position of the left side of the segment
      const startDate = new Date(start);
      const secondsSinceMidnight = 
        startDate.getSeconds() + 
        (startDate.getMinutes() * 60) + 
        (startDate.getHours() * 3600);
      const percentOfDay = (secondsSinceMidnight / (24 * 3600)) * 100;
      
      if (i !== 0) {
        segments.push(
          <div
            key={`line-${i}`}
            className={classes.verticalLine}
            style={{ left: `${percentOfDay}%` }}
          />
        );
      }

      segments.push(
        <div
          key={`segment-${index}-${i}`}
          className={classes.segment}
          style={{ left: `${percentOfDay}%`, width: `${width}%`, backgroundColor: color }}
          onMouseOver={(e) => handleHover(e, logs[i], start, end, i, index)}
          onMouseOut={() => {
            const segmentLabel = document.getElementById(`segmentLabel-${index}-${i}`);
            if (!segmentLabel) return;
            segmentLabel.style.visibility = 'hidden';
          }}
        >
          <div id={`segmentLabel-${index}-${i}`} className={classes.segmentLabel}>
            {errorMapping[logs[i].s] || 'Unmapped error'}: {new Date(start).toLocaleTimeString()} to {new Date(end).toLocaleTimeString()}
          </div>
        </div>
      );
    }
    return segments;
  };  

  const renderTimeLabels = () => {
    return new Array(24).fill(null).map((_, hour) => {
      let displayHour = hour % 12 || 12;
      const amPm = hour >= 12 ? 'PM' : 'AM';
      return (
        <span 
          key={hour} 
          className={classes.timeLabel} 
          style={{ left: `${(hour / 24) * 100}%` }}
        >
          {`${displayHour} ${amPm}`}
        </span>
      );
    });
  }

  return (
    <Paper className={classes.timelineContainer}>
      <Box className={classes.boxLayout}>
        <TextField
          label="Camera ID"
          variant="outlined"
          value={camera.cameraId}
          onChange={(value) => {
            dispatch({ type: SET_CAMERA_CAMERAID, payload: value.target.value });
          }}
        />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Date"
            value={dayjs(camera.date)}
            onChange={(date) => {
              if (!date) return;
              const dateMod = `${date.year()}-${String(date.month() + 1).padStart(2, '0')}-${String(date.date()).padStart(2, '0')}`;
              dispatch({ type: SET_CAMERA_DATE, payload: dateMod  });
            }}
          />
        </LocalizationProvider>
      </Box>
      {!!Object.keys(camera.information).length && Object.keys(camera.information).sort((a, b) => Date.parse(a) - Date.parse(b)).map((date, index) => (
        <div 
          key={date} 
          className={classes.segmentWrapper}
        >
          <h3 className={classes.timeLineLabel}>
            {new Date(date).toLocaleDateString()}
          </h3>
          <div className={classes.timeLine} /> {/* The horizontal line */}
          {renderSegments(camera.information[date], index)}
          {renderTimeLabels()}
        </div>
      ))}
    </Paper>
  );
};