export const SET_OPERATION_IN_PROGRESS = 'SET_OPERATION_IN_PROGRESS';
export const SET_IN_LINE_OPERATION_IN_PROGRESS = 'SET_IN_LINE_OPERATION_IN_PROGRESS';
export const SET_IN_IN_LINE_OPERATION_IN_PROGRESS = 'SET_IN_IN_LINE_OPERATION_IN_PROGRESS';

export const GET_FOLDERS = 'GET_FOLDERS';
export const SET_FOLDERS = 'SET_FOLDERS';

export const GET_INCIDENT = 'GET_INCIDENT';
export const SET_INCIDENT = 'SET_INCIDENT';

export const GET_INCIDENTS = 'GET_INCIDENTS';
export const SET_INCIDENTS = 'SET_INCIDENTS';

export const GET_INCIDENT_RESTORE = 'GET_INCIDENT_RESTORE';

export const GET_INCIDENT_ADDITIONAL = 'GET_INCIDENT_ADDITIONAL';
export const SET_INCIDENT_ADDITIONAL = 'SET_INCIDENT_ADDITIONAL';

export const GET_DATA_EXPLORER = 'GET_DATA_EXPLORER';
export const SET_DATA_EXPLORER = 'SET_DATA_EXPLORER';

export const LOG_IN = "LOG_IN";
export const LOG_OUT = "LOG_OUT";
export const SET_USER = "SET_USER";
export const GET_USER = "GET_USER";
export const SIGN_UP = "SIGN_UP";

export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUBMIT = "FORGOT_PASSWORD_SUBMIT";
export const SET_EMAIL = "SET_EMAIL";
export const SET_NEW_PASSWORD = "SET_NEW_PASSWORD";
export const SET_CONFIRM_NEW_PASSWORD = "SET_CONFIRM_NEW_PASSWORD";
export const SET_CONFIRMATION_CODE = "SET_CONFIRMATION_CODE";
export const SET_CONFIRMATION_CODE_SENT = "SET_CONFIRMATION_CODE_SENT";

export const SET_DATA_EXPLORER_id = 'SET_DATA_EXPLORER_id';
export const SET_DATA_EXPLORER_ROADWAY = 'SET_DATA_EXPLORER_ROADWAY';
export const SET_DATA_EXPLORER_DATE = 'SET_DATA_EXPLORER_DATE';
export const SET_DATA_EXPLORER_DATERANGE = 'SET_DATA_EXPLORER_DATERANGE';
export const SET_DATA_EXPLORER_COUNTY = 'SET_DATA_EXPLORER_COUNTY';
export const SET_DATA_EXPLORER_SEVERITY = 'SET_DATA_EXPLORER_SEVERITY';
export const SET_DATA_EXPLORER_DIRECTION = 'SET_DATA_EXPLORER_DIRECTION';
export const SET_DATA_EXPLORER_DESCRIPTION = 'SET_DATA_EXPLORER_DESCRIPTION';
export const SET_DATA_EXPLORER_LIMIT = 'SET_DATA_EXPLORER_LIMIT';
export const SET_DATA_EXPLORER_OFFSET = 'SET_DATA_EXPLORER_OFFSET';
export const SET_DATA_EXPLORER_PAGE = 'SET_DATA_EXPLORER_PAGE';
export const SET_DATA_EXPLORER_SORT = 'SET_DATA_EXPLORER_SORT';
export const SET_DATA_EXPLORER_ORDER = 'SET_DATA_EXPLORER_ORDER';
export const SET_DATA_EXPLORER_ORDER_SORT = 'SET_DATA_EXPLORER_ORDER_SORT';
export const SET_DATA_EXPLORER_PAGECOUNT = 'SET_DATA_EXPLORER_PAGECOUNT';
export const SET_DATA_EXPLORER_COUNT = 'SET_DATA_EXPLORER_COUNT';
export const SET_DATA_EXPLORER_CASE_ID_DESCRIPTION = 'SET_DATA_EXPLORER_CASE_ID_DESCRIPTION';
export const SET_DATA_EXPLORER_OFFSET_PAGE = 'SET_DATA_EXPLORER_OFFSET_PAGE';
export const SET_DATA_EXPLORER_SELECTED_ROWS = 'SET_DATA_EXPLORER_SELECTED_ROWS'

export const SET_CAMERA_LOGS = 'SET_CAMERA_LOGS';
export const SET_CAMERA_CAMERAID = 'SET_CAMERA_CAMERAID';
export const SET_CAMERA_DATE = 'SET_CAMERA_DATE';
export const GET_CAMERA_LOGS = 'GET_CAMERA_LOGS';
export const SET_CAMERA = 'SET_CAMERA';

export const RESET_DATA_EXPLORER = 'RESET_DATA_EXPLORER';

export const SET_LOGIN_TAB = 'SET_LOGIN_TAB';

export const SET_DATE_BUFFER = 'SET_DATE_BUFFER';
export const SET_COORDINATE_BUFFER = 'SET_COORDINATE_BUFFER';