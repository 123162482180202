import { all, put, select, takeEvery, takeLatest} from "redux-saga/effects";
import { AuthResult, User } from "../Models/User";
import { FORGOT_PASSWORD, FORGOT_PASSWORD_SUBMIT, GET_FOLDERS, GET_INCIDENT, GET_INCIDENTS, GET_INCIDENT_ADDITIONAL, GET_INCIDENT_RESTORE, GET_USER, LOG_IN, LOG_OUT, SET_CONFIRMATION_CODE_SENT, SET_DATA_EXPLORER, SET_DATA_EXPLORER_SELECTED_ROWS, SET_FOLDERS, SET_INCIDENT, SET_INCIDENTS, SET_INCIDENT_ADDITIONAL, SET_IN_IN_LINE_OPERATION_IN_PROGRESS, SET_IN_LINE_OPERATION_IN_PROGRESS, SET_LOGIN_TAB, SET_OPERATION_IN_PROGRESS, SET_USER, SIGN_UP } from "./Actions";
import { forgotPassword, getCurrentUser, signIn, signOut, signUp, forgotPasswordSubmit as forgotPasswordSubmitAPI } from "./helpers";
import { Notification } from "../Utils/Notification";
import { toast } from "react-hot-toast";
import { getFilesAPI, getIncidentAPI, getIncidentAdditionalAPI, getIncidentRestoreAPI, getIncidentsAPI } from "../Utils/Api";
import { getDataExplorer, getIncident, getIncidentMapInput, getUserSelect, getUserToken as getUserTokenSelector } from "./Selectors";
import { Folders } from "../Models/Folder";
import { IncidentInformation, IncidentMapInput, IncidentSpecific, IncidentVideos } from "../Models/Incident";
import { DataExplorer } from "../Models/DataExplorer";
import { initialState } from "./Reducer";
import { applicationEnv } from "../Utils/Fetch";

function* logInUser(action: any) {
  yield put ({ type: SET_OPERATION_IN_PROGRESS, payload: true });
  try {
    let logInResult: AuthResult = yield signIn(action.payload);
    if (logInResult.success) {
      yield put({ type: GET_USER, payload: {} });
      toast.success("Logged In!");
    } else {
      Notification(JSON.stringify(logInResult.message));
    }
  } catch (e) {
    try {
      let logInResult: AuthResult = yield signIn(action.payload);
      if (logInResult.success) {
        yield put({ type: GET_USER, payload: {} });
        toast.success("Logged In!");
      } else {
        Notification(JSON.stringify(logInResult.message));
      }
    } catch (e) {
      toast.error(JSON.stringify(e));
    }
  } finally {
    yield put ({ type: SET_OPERATION_IN_PROGRESS, payload: false });
  }
}

function* logOutUser() {
  yield put ({ type: SET_OPERATION_IN_PROGRESS, payload: true });
  try {
    let signOutResult: AuthResult = yield signOut();
    if (signOutResult.success) {
      yield put({ type: SET_USER, payload: { loaded: true } });
      toast.success("Logged Out!");
    } else {
      Notification(JSON.stringify(signOutResult.message));
    }
  } catch (e) {
    try {
      let signOutResult: AuthResult = yield signOut();
      if (signOutResult.success) {
        yield put({ type: SET_USER, payload: { loaded: true } });
        toast.success("Logged Out!");
      } else {
        Notification(JSON.stringify(signOutResult.message));
      }
    } catch (e) {
      Notification(JSON.stringify(e));
    }
  } finally {
    yield put ({ type: SET_OPERATION_IN_PROGRESS, payload: false });
  }
}

function* getUser() {
  yield put ({ type: SET_OPERATION_IN_PROGRESS, payload: true });
  try {
    let appUser: AuthResult = yield getCurrentUser();
    if (appUser.success) {
      const userObj = {
        attributes: {
          email: appUser.user.attributes.email,
          name: appUser.user.attributes.name,
        },
        loaded: true,
        userToken: appUser.user.signInUserSession.idToken.jwtToken,
      }
      yield put({ type: SET_USER, payload: {...userObj}});
    } else {
      yield put({ type: SET_USER, payload: {loaded: true}});
    }
  } catch (e) {
    try {
      let appUser: AuthResult = yield getCurrentUser();
      if (appUser.success) {
        const userObj = {
          attributes: {
            email: appUser.user.attributes.email,
            name: appUser.user.attributes.name,
          },
          loaded: true,
          userToken: appUser.user.signInUserSession.idToken.jwtToken,
        }
        yield put({ type: SET_USER, payload: {...userObj}});
      } else {
        yield put({ type: SET_USER, payload: {loaded: true}});
      }
    } catch (e) {
      yield put({ type: SET_USER, payload: {loaded: true}});
    }
  } finally {
    yield put ({ type: SET_OPERATION_IN_PROGRESS, payload: false });
  }
}

function* signUpUser(action: any) {
  yield put ({ type: SET_OPERATION_IN_PROGRESS, payload: true });
  try {
    let signUpResult: AuthResult = yield signUp(action.payload);
    if (signUpResult.success) {
      yield put({ type: SET_USER, payload: action.payload });
      toast.success("Sign up successful! We will soon process your account", {
        duration: 5000,
      });
    } else {
      toast.error(JSON.stringify(signUpResult.message));
    }
  } catch (e) {
    try {
      let signUpResult: AuthResult = yield signUp(action.payload);
      if (signUpResult.success) {
        yield put({ type: SET_USER, payload: action.payload });
        toast.success("Sign up successful! We will soon process your account", {
          duration: 5000,
        });
      } else {
        toast.error(JSON.stringify(signUpResult.message));
      }
    } catch (e) {
      toast.error(JSON.stringify(e));
    }
  } finally {
    yield put ({ type: SET_OPERATION_IN_PROGRESS, payload: false });
  }
}

function* getFolders(action: any) {
  yield put ({ type: SET_IN_LINE_OPERATION_IN_PROGRESS, payload: true });
  try {
    let userToken: string = yield select(getUserTokenSelector);
    const response: {data: Folders} = yield getFilesAPI(userToken, action.payload?.month, action.payload?.year);
    yield put({ type: SET_FOLDERS, payload: { folders: response.data }});

  } catch (e) {
      toast.error("Error fetching folders, Please try again later");

  } finally {
    yield put ({ type: SET_IN_LINE_OPERATION_IN_PROGRESS, payload: false });
  }
}

function* getIncidentAction(action: any) {
  yield put ({ type: SET_IN_LINE_OPERATION_IN_PROGRESS, payload: true });
  try {
    let userToken: string = yield select(getUserTokenSelector);
    const response: {data: { videos: IncidentVideos, incidentInformation: IncidentInformation, videosAvailability: string, videosRestorationExpiryDate: string, videosRestorationInitialized: string }} = yield getIncidentAPI(userToken, action.payload);
    const obj = {
      ...initialState.incident,
      videos: response.data.videos,
      incidentInformation: response.data.incidentInformation,
      videosAvailability: response.data.videosAvailability,
      videosRestorationExpiryDate: response.data.videosRestorationExpiryDate,
      videosRestorationInitialized: response.data.videosRestorationInitialized,
    }
    yield put({ type: SET_INCIDENT, payload: obj });
  } catch (e) {
    try {
      let userToken: string = yield select(getUserTokenSelector);
      const response: IncidentSpecific = yield getIncidentAPI(userToken, action.payload); 
      yield put({ type: SET_INCIDENT, payload: response });
    } catch (e) {
      toast.error("Error fetching incident, Please try again later");
    }
  } finally {
    yield put ({ type: SET_IN_LINE_OPERATION_IN_PROGRESS, payload: false });
  }
}

function* getIncidentAdditional(action: any) {
  yield put ({ type: SET_IN_IN_LINE_OPERATION_IN_PROGRESS, payload: true });
  try {
    const incident: IncidentSpecific = yield select(getIncident);
    const mapInput: IncidentMapInput = yield select(getIncidentMapInput);

    let userToken: string = yield select(getUserTokenSelector);

    let cameras = incident.incidentInformation.distanceCameraId;

    if (applicationEnv === 'fl511') {
      cameras = incident.incidentInformation.associatedCameraId.concat(cameras);
    } else {
      cameras = Object.keys(incident.videos)
    }

    let response: {data: IncidentSpecific} = yield getIncidentAdditionalAPI(
      userToken,
      cameras,
      { longitude: incident.incidentInformation.longitude, latitude: incident.incidentInformation.latitude },
      incident.incidentInformation.startTime,
      mapInput.dateBuffer,
      mapInput.coordinateBuffer,
    ); 

    yield put({ type: SET_INCIDENT_ADDITIONAL, payload: {
      cameraInformation: response.data.cameraInformation,
      events: response.data.events,
    }});
  } catch (e) {
      toast.error("Error fetching additional incident information, Please try again later");
      console.error(e);
  } finally {
    yield put ({ type: SET_IN_IN_LINE_OPERATION_IN_PROGRESS, payload: false });
  }
}

function* getIncidents() {
  yield put ({ type: SET_IN_LINE_OPERATION_IN_PROGRESS, payload: true });
  try {
    yield put ({ type: SET_DATA_EXPLORER_SELECTED_ROWS, payload: { selectedRows: [] }})
    const userToken: string = yield select(getUserTokenSelector);
    const incidentData: DataExplorer = yield select(getDataExplorer);

    const response: {data: { count: number, incidents: IncidentSpecific[]} } = yield getIncidentsAPI(userToken, incidentData); 

    if (response.data.count) {
      yield put({ type: SET_DATA_EXPLORER, payload: {...incidentData, count: response.data.count, pageCount: Math.ceil(response.data.count / incidentData.limit) } });
    }

    yield put({ type: SET_INCIDENTS, payload: response.data.incidents });

  } catch (e) {
      console.error(e)
      toast.error("Error fetching incidents, Please try again later");

  } finally {
    yield put ({ type: SET_IN_LINE_OPERATION_IN_PROGRESS, payload: false });
  }
}

function* forgotPasswordRequest() {
  yield put ({ type: SET_OPERATION_IN_PROGRESS, payload: true });

  try {
    const user: User = yield select(getUserSelect);
    const response: AuthResult = yield forgotPassword(user.forgotPassword.email);
    if (response.success) {
      yield put({ type: SET_CONFIRMATION_CODE_SENT, payload: true });
      toast.success("Password reset code sent to your email", {
        duration: 5000,
      });
    } else {
      toast.error(JSON.stringify(response.message));
      yield put({ type: SET_CONFIRMATION_CODE_SENT, payload: false });
    }
  } catch (e) {
    toast.error(JSON.stringify(e));
    yield put({ type: SET_CONFIRMATION_CODE_SENT, payload: false });
  } finally {
    yield put ({ type: SET_OPERATION_IN_PROGRESS, payload: false });
  }
}

function* forgotPasswordSubmit() {
  yield put ({ type: SET_OPERATION_IN_PROGRESS, payload: true });
  try {
    const user: User = yield select(getUserSelect);
    const response: AuthResult = yield forgotPasswordSubmitAPI(user.forgotPassword.email, user.forgotPassword.confirmationCode, user.forgotPassword.newPassword);
    if (response.success) {
      yield put({ type: SET_CONFIRMATION_CODE_SENT, payload: false });
      toast.success("Password reset successful", {
        duration: 5000,
      });
      yield put({ type: SET_LOGIN_TAB, payload: "login" })
    } else {
      toast.error(JSON.stringify(response.message));
      yield put({ type: SET_CONFIRMATION_CODE_SENT, payload: true });
    }
  } catch (e) {
    toast.error(JSON.stringify(e));
    yield put({ type: SET_CONFIRMATION_CODE_SENT, payload: true });
  } finally {
    yield put ({ type: SET_OPERATION_IN_PROGRESS, payload: false });
  }
}

function* getIncidentRestore() {
  yield put ({ type: SET_IN_LINE_OPERATION_IN_PROGRESS, payload: true });
  try {
    let userToken: string = yield select(getUserTokenSelector);
    const incident: IncidentSpecific = yield select(getIncident);

    const response: {data: { message?: string, error?: string }} = yield getIncidentRestoreAPI(userToken, incident.incidentInformation.id);
    
    if (response.data.message) {
      toast.success(response.data.message || "Incident restoration started");
    } else {
      toast.error(response.data.error || "Error restoring incident");
    }

    yield put({ type: GET_INCIDENT, payload: incident.incidentInformation.id });
    yield put({ type: GET_INCIDENT_ADDITIONAL, payload: {} });
  } catch (e) {
    toast.error("Error fetching incident, Please try again later");
  } finally {
    yield put ({ type: SET_IN_LINE_OPERATION_IN_PROGRESS, payload: false });
  }
}

function* projectSaga() {
    yield all([
      takeEvery(LOG_IN, logInUser),
      takeEvery(LOG_OUT, logOutUser),
      takeEvery(GET_USER, getUser),
      takeEvery(SIGN_UP, signUpUser),
      takeLatest(GET_FOLDERS, getFolders),
      takeLatest(GET_INCIDENT, getIncidentAction),
      takeLatest(GET_INCIDENTS, getIncidents),
      takeLatest(GET_INCIDENT_ADDITIONAL, getIncidentAdditional),
      takeLatest(FORGOT_PASSWORD, forgotPasswordRequest),
      takeLatest(FORGOT_PASSWORD_SUBMIT, forgotPasswordSubmit),
      takeLatest(GET_INCIDENT_RESTORE, getIncidentRestore)
    ]);
}
  
export default projectSaga;
