import { Auth } from "aws-amplify";
import { User } from "../Models/User";
import { IncidentSpecific } from "../Models/Incident";

export async function signIn(user: User) {
	try {
		const result = await Auth.signIn({
			username: user.manage.email,
			password: user.manage.password,
		});
		return {
			success: true,
			user: result,
		}
	} catch (e: any) {		
		return {
			success: false,
			message: e,
		}
	}
}

export async function signOut() {
	try {
		await Auth.signOut();
		return {
			success: true,
			message: "Success!",
		}
	} catch (e: any) {
		return {
			success: false,
			message: e.message,
		}
	}
}

export async function getCurrentUser() {
  try {
    const user = await Auth.currentAuthenticatedUser();
		return {
			success: true,
			user: user,
		}
  } catch (e: any) {
		return {
			success: false,
			message: e,
		}
  }
}

export async function signUp(user: User) {
	try {
	  await Auth.signUp({
			username: user.manage.email,
			password: user.manage.password,
			attributes: {
				name: user.manage.name,
			},
	  });
		return {
			success: true,
			message: "Success!",
		}
	} catch (e: any) {
		return {
			success: false,
			message: e.message,
		}
	}
}

export function convertApiResponseToIncidentArray(responseData: any): IncidentSpecific[] {
	return responseData.map((response: any) => {
	  return { incidentInformation: response, videos: [] };
	});
}

export async function forgotPassword(username: string) {
	try {
		await Auth.forgotPassword(username);
		return {
			success: true,
			message: "Success!",
		}
	} catch (e: any) {
		return {
			success: false,
			message: e.message,
		}
	}
}

export async function forgotPasswordSubmit(username: string, code: string, newPassword: string) {
	try {
		await Auth.forgotPasswordSubmit(username, code, newPassword);
		return {
			success: true,
			message: "Success!",
		}
	} catch (e: any) {
		return {
			success: false,
			message: e.message,
		}
	}
}
