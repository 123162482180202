import React from 'react';
import Logo from '../../../Assets/PKTraffic Logo.jpeg';
import Symbol from '../../../Assets/PKTraffic Symbol.png';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  logo: {
    width: '80%',
    height: '80%',
    objectFit: 'contain',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
});

export const SidebarHeader: React.FC<{ collapsed: boolean }> = ({ collapsed }) => {

  const classes = useStyles();

  return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {collapsed ? (
          <img src={Symbol} alt="Logo" className={classes.logo} />
        ) : (
          <img src={Logo} alt="Logo" className={classes.logo} />
        )}
      </div>
  );
};