import toast from "react-hot-toast";

export function Notification(notification: string) {
    switch (true) {
        case notification.includes('UserNotConfirmedException'):
            toast.error("User not confirmed. We will soon accept your request. Please try again later.");
            break;
        case notification.includes('UserNotFoundException'):
            toast.error("User not found. Please check your email and password.");
            break;
        case notification.includes('NotAuthorizedException'):
            toast.error("User not authorized. Please check your email and password.");
            break;
        case notification.includes('InvalidParameterException'):
            toast.error("Invalid parameter. Please check your email and password.");
            break;
        default:
            toast.error("Something went wrong. Please try again later.");
            break;
    }
}