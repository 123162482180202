export const direction = [
  {'value': 'E', 'display': 'E'},
  {'value': 'W', 'display': 'W'},
  {'value': 'S', 'display': 'S'},
  {'value': 'N', 'display': 'N'}
]


export const limits = [5,10,15,20,50,100]


export const county = [
  { value: "Alachua", display: "Alachua" },
  { value: "Baker", display: "Baker" },
  { value: "Bay", display: "Bay" },
  { value: "Brevard", display: "Brevard" },
  { value: "Broward", display: "Broward" },
  { value: "Charlotte", display: "Charlotte" },
  { value: "Citrus", display: "Citrus" },
  { value: "Clay", display: "Clay" },
  { value: "Collier", display: "Collier" },
  { value: "Columbia", display: "Columbia" },
  { value: "DeSoto", display: "DeSoto" },
  { value: "Dixie", display: "Dixie" },
  { value: "Duval", display: "Duval" },
  { value: "Escambia", display: "Escambia" },
  { value: "Flagler", display: "Flagler" },
  { value: "Gadsden", display: "Gadsden" },
  { value: "Glades", display: "Glades" },
  { value: "Hamilton", display: "Hamilton" },
  { value: "Hardee", display: "Hardee" },
  { value: "Hendry", display: "Hendry" },
  { value: "Hernando", display: "Hernando" },
  { value: "Highlands", display: "Highlands" },
  { value: "Hillsborough", display: "Hillsborough" },
  { value: "Holmes", display: "Holmes" },
  { value: "Indian River", display: "Indian River" },
  { value: "Jackson", display: "Jackson" },
  { value: "Jefferson", display: "Jefferson" },
  { value: "Lake", display: "Lake" },
  { value: "Lee", display: "Lee" },
  { value: "Leon", display: "Leon" },
  { value: "Levy", display: "Levy" },
  { value: "Madison", display: "Madison" },
  { value: "Manatee", display: "Manatee" },
  { value: "Marion", display: "Marion" },
  { value: "Martin", display: "Martin" },
  { value: "Miami-Dade", display: "Miami-Dade" },
  { value: "Monroe", display: "Monroe" },
  { value: "Nassau", display: "Nassau" },
  { value: "Okaloosa", display: "Okaloosa" },
  { value: "Okeechobee", display: "Okeechobee" },
  { value: "Orange", display: "Orange" },
  { value: "Osceola", display: "Osceola" },
  { value: "Palm Beach", display: "Palm Beach" },
  { value: "Pasco", display: "Pasco" },
  { value: "Pinellas", display: "Pinellas" },
  { value: "Polk", display: "Polk" },
  { value: "Putnam", display: "Putnam" },
  { value: "Santa Rosa", display: "Santa Rosa" },
  { value: "Sarasota", display: "Sarasota" },
  { value: "Seminole", display: "Seminole" },
  { value: "St. Johns", display: "St. Johns" },
  { value: "St. Lucie", display: "St. Lucie" },
  { value: "Sumter", display: "Sumter" },
  { value: "Suwannee", display: "Suwannee" },
  { value: "Taylor", display: "Taylor" },
  { value: "Volusia", display: "Volusia" },
  { value: "Wakulla", display: "Wakulla" },
  { value: "Walton", display: "Walton" },
  { value: "Washington", display: "Washington" },
];


export const roadway = [
  { value: "", display: "" },
  { value: "15th St. E", display: "15th St. E" },
  { value: "1ST ST N", display: "1ST ST N" },
  { value: "295 East Express", display: "295 East Express" },
  { value: "295 West Express", display: "295 West Express" },
  { value: "301", display: "301" },
  { value: "3rd St", display: "3rd St" },
  { value: "441", display: "441" },
  { value: "53rd Ave E", display: "53rd Ave E" },
  { value: "53rd Ave W", display: "53rd Ave W" },
  { value: "75 Express", display: "75 Express" },
  { value: "826 Express", display: "826 Express" },
  { value: "92", display: "92" },
  { value: "95 Express", display: "95 Express" },
  { value: "ALICO RD", display: "ALICO RD" },
  { value: "Acosta Bridge", display: "Acosta Bridge" },
  { value: "Airport Blvd", display: "Airport Blvd" },
  { value: "Alafaya Trl", display: "Alafaya Trl" },
  { value: "Aloma Ave", display: "Aloma Ave" },
  { value: "Alt. A1A", display: "Alt. A1A" },
  { value: "Altamonte Dr", display: "Altamonte Dr" },
  { value: "Americana Blvd", display: "Americana Blvd" },
  { value: "Apopka Vineland Rd", display: "Apopka Vineland Rd" },
  { value: "Archer Rd", display: "Archer Rd" },
  { value: "Arlington Expressway", display: "Arlington Expressway" },
  { value: "Atlantic Blvd", display: "Atlantic Blvd" },
  { value: "Aurora Rd", display: "Aurora Rd" },
  { value: "Australian Avenue", display: "Australian Avenue" },
  { value: "Avalon Rd", display: "Avalon Rd" },
  { value: "BEE RIDGE RD", display: "BEE RIDGE RD" },
  { value: "BONITA BEACH RD", display: "BONITA BEACH RD" },
  { value: "Babcock St", display: "Babcock St" },
  { value: "Back Beach Rd", display: "Back Beach Rd" },
  { value: "Banyan Blvd", display: "Banyan Blvd" },
  { value: "Barnes Blvd", display: "Barnes Blvd" },
  { value: "Baseline Rd", display: "Baseline Rd" },
  { value: "Baymeadows Rd", display: "Baymeadows Rd" },
  { value: "Bayshore Rd", display: "Bayshore Rd" },
  { value: "Beach Blvd", display: "Beach Blvd" },
  { value: "Bee Ridge Rd", display: "Bee Ridge Rd" },
  { value: "Belvedere Road", display: "Belvedere Road" },
  { value: "Beville Rd", display: "Beville Rd" },
  { value: "Biscayne Blvd", display: "Biscayne Blvd" },
  { value: "Blanding Blvd", display: "Blanding Blvd" },
  { value: "Blitchton Rd", display: "Blitchton Rd" },
  { value: "Blue Star Hwy", display: "Blue Star Hwy" },
  { value: "Boggy Creek Rd", display: "Boggy Creek Rd" },
  { value: "Bonita Beach Rd", display: "Bonita Beach Rd" },
  { value: "Brent Ln", display: "Brent Ln" },
  { value: "Brickyard Rd", display: "Brickyard Rd" },
  { value: "Broadway", display: "Broadway" },
  { value: "Bronson Hwy", display: "Bronson Hwy" },
  { value: "Bruce B Downs Blvd", display: "Bruce B Downs Blvd" },
  { value: "Buenaventura Blvd", display: "Buenaventura Blvd" },
  { value: "Butler Boulevard", display: "Butler Boulevard" },
  { value: "CLARK RD", display: "CLARK RD" },
  { value: "COLLIER BLVD", display: "COLLIER BLVD" },
  { value: "CORKSCREW RD", display: "CORKSCREW RD" },
  { value: "CR-15", display: "CR-15" },
  { value: "CR-189 (Log Lake Rd)", display: "CR-189 (Log Lake Rd)" },
  { value: "CR-191 (Garcon Point Rd)", display: "CR-191 (Garcon Point Rd)" },
  { value: "CR-257 (Salt Rd)", display: "CR-257 (Salt Rd)" },
  { value: "CR-270A", display: "CR-270A" },
  { value: "CR-279", display: "CR-279" },
  { value: "CR-286", display: "CR-286" },
  { value: "CR-314", display: "CR-314" },
  { value: "CR-318", display: "CR-318" },
  { value: "CR-4146", display: "CR-4146" },
  { value: "CR-4162", display: "CR-4162" },
  { value: "CR-419", display: "CR-419" },
  { value: "CR-424", display: "CR-424" },
  { value: "CR-427", display: "CR-427" },
  { value: "CR-431", display: "CR-431" },
  { value: "CR-431B", display: "CR-431B" },
  { value: "CR-435", display: "CR-435" },
  { value: "CR-439", display: "CR-439" },
  { value: "CR-46A", display: "CR-46A" },
  { value: "CR-470", display: "CR-470" },
  { value: "CR-475", display: "CR-475" },
  { value: "CR-48", display: "CR-48" },
  { value: "CR-484", display: "CR-484" },
  { value: "CR-502", display: "CR-502" },
  { value: "CR-509", display: "CR-509" },
  { value: "CR-511", display: "CR-511" },
  { value: "CR-516", display: "CR-516" },
  { value: "CR-527", display: "CR-527" },
  { value: "CR-529", display: "CR-529" },
  { value: "CR-532", display: "CR-532" },
  { value: "CR-535", display: "CR-535" },
  { value: "CR-561", display: "CR-561" },
  { value: "CR-582a", display: "CR-582a" },
  { value: "CR-89 (Ward Basin Rd)", display: "CR-89 (Ward Basin Rd)" },
  { value: "Card Sound Road", display: "Card Sound Road" },
  { value: "Cassia Church Rd", display: "Cassia Church Rd" },
  { value: "Central Florida Greeneway", display: "Central Florida Greeneway" },
  { value: "Central Florida Pkwy", display: "Central Florida Pkwy" },
  { value: "Champions Gate", display: "Champions Gate" },
  { value: "Chancery Ln", display: "Chancery Ln" },
  { value: "Cheney Hwy", display: "Cheney Hwy" },
  { value: "Chickasaw Trail", display: "Chickasaw Trail" },
  { value: "Chickasaw Trl", display: "Chickasaw Trl" },
  { value: "Chuluota Rd", display: "Chuluota Rd" },
  { value: "Clarcona Ocoee Rd", display: "Clarcona Ocoee Rd" },
  { value: "Clark Rd", display: "Clark Rd" },
  { value: "College Rd", display: "College Rd" },
  { value: "Collier Blvd", display: "Collier Blvd" },
  { value: "Colonial Blvd", display: "Colonial Blvd" },
  { value: "Columbia Blvd", display: "Columbia Blvd" },
  { value: "Commercial Blvd", display: "Commercial Blvd" },
  { value: "Conroy Rd", display: "Conroy Rd" },
  { value: "Conroy Windermere Rd", display: "Conroy Windermere Rd" },
  { value: "Convention Way", display: "Convention Way" },
  { value: "Conway Rd", display: "Conway Rd" },
  { value: "Cortez Rd W", display: "Cortez Rd W" },
  { value: "Courtenay Pkwy", display: "Courtenay Pkwy" },
  { value: "Curry Ford Rd", display: "Curry Ford Rd" },
  { value: "Cypress Pkwy", display: "Cypress Pkwy" },
  { value: "DANIELS PARKWAY", display: "DANIELS PARKWAY" },
  { value: "Dairy Rd", display: "Dairy Rd" },
  { value: "Dale Mabry Hwy", display: "Dale Mabry Hwy" },
  { value: "Davis Blvd", display: "Davis Blvd" },
  { value: "Davis Hwy", display: "Davis Hwy" },
  { value: "Dean Rd", display: "Dean Rd" },
  { value: "Debary Ave", display: "Debary Ave" },
  { value: "Dirksen Dr", display: "Dirksen Dr" },
  { value: "Dunlawton Ave", display: "Dunlawton Ave" },
  { value: "Dunn Ave", display: "Dunn Ave" },
  { value: "E 25th St", display: "E 25th St" },
  { value: "E Airport Blvd", display: "E Airport Blvd" },
  { value: "E Anderson St", display: "E Anderson St" },
  { value: "E Colonial Dr", display: "E Colonial Dr" },
  { value: "E Industry Rd", display: "E Industry Rd" },
  {
    value: "E International Speedway Blvd",
    display: "E International Speedway Blvd",
  },
  { value: "E Keene Rd", display: "E Keene Rd" },
  { value: "E Lake Mary Blvd", display: "E Lake Mary Blvd" },
  { value: "E Mitchell Hammock Rd", display: "E Mitchell Hammock Rd" },
  { value: "E SR-50", display: "E SR-50" },
  { value: "E Semoran Blvd", display: "E Semoran Blvd" },
  { value: "E South St", display: "E South St" },
  { value: "E Sr-434", display: "E Sr-434" },
  { value: "East-West Expy", display: "East-West Expy" },
  { value: "Econlockhatchee Trl", display: "Econlockhatchee Trl" },
  { value: "Edgewater Dr", display: "Edgewater Dr" },
  { value: "Eglin Pkwy", display: "Eglin Pkwy" },
  { value: "Ellis Rd", display: "Ellis Rd" },
  { value: "Emerald Coast Pkwy", display: "Emerald Coast Pkwy" },
  { value: "Emerson Dr", display: "Emerson Dr" },
  { value: "FRUITVILLE RD", display: "FRUITVILLE RD" },
  { value: "Fairbanks Ave", display: "Fairbanks Ave" },
  { value: "Fairfield Dr", display: "Fairfield Dr" },
  { value: "Fay Blvd", display: "Fay Blvd" },
  { value: "First Coast Expressway", display: "First Coast Expressway" },
  { value: "Fiske Blvd", display: "Fiske Blvd" },
  { value: "Flagler Drive", display: "Flagler Drive" },
  { value: "Flat Creek Rd", display: "Flat Creek Rd" },
  { value: "Florida Ave", display: "Florida Ave" },
  { value: "Floridas Turnpike", display: "Floridas Turnpike" },
  {
    value: "Floridas Turnpike Golden Glades Spur",
    display: "Floridas Turnpike Golden Glades Spur",
  },
  { value: "Forest City Rd", display: "Forest City Rd" },
  { value: "Fortune Rd", display: "Fortune Rd" },
  { value: "Fowler Ave", display: "Fowler Ave" },
  { value: "Fowler St.", display: "Fowler St." },
  { value: "Fruitville Rd", display: "Fruitville Rd" },
  { value: "Ft Brooks Rd", display: "Ft Brooks Rd" },
  { value: "GANDY BLVD", display: "GANDY BLVD" },
  { value: "GOLDEN GATE PKWY", display: "GOLDEN GATE PKWY" },
  { value: "Gandy Blvd", display: "Gandy Blvd" },
  { value: "Gibsonton Drive", display: "Gibsonton Drive" },
  { value: "Goldenrod Rd", display: "Goldenrod Rd" },
  { value: "Good Homes Rd", display: "Good Homes Rd" },
  { value: "Grand Meadows Blvd", display: "Grand Meadows Blvd" },
  { value: "Grand National Dr", display: "Grand National Dr" },
  { value: "Greensboro Hwy", display: "Greensboro Hwy" },
  { value: "Griffin Road", display: "Griffin Road" },
  { value: "HARBOR VIEW ROAD", display: "HARBOR VIEW ROAD" },
  { value: "Hallandale Beach Blvd", display: "Hallandale Beach Blvd" },
  { value: "Harbor Blvd", display: "Harbor Blvd" },
  { value: "Harden Blvd", display: "Harden Blvd" },
  { value: "Hart Bridge", display: "Hart Bridge" },
  { value: "Hathaway Brg", display: "Hathaway Brg" },
  { value: "Hiawassee Rd", display: "Hiawassee Rd" },
  { value: "Highway 231", display: "Highway 231" },
  { value: "Highway 90", display: "Highway 90" },
  { value: "Hoffner Ave", display: "Hoffner Ave" },
  { value: "Hoffner Rd", display: "Hoffner Rd" },
  { value: "Holden Ave", display: "Holden Ave" },
  { value: "Hollywood Blvd", display: "Hollywood Blvd" },
  { value: "Howell Branch Rd", display: "Howell Branch Rd" },
  { value: "I-10", display: "I-10" },
  { value: "I-110", display: "I-110" },
  { value: "I-175", display: "I-175" },
  { value: "I-195", display: "I-195" },
  { value: "I-275", display: "I-275" },
  { value: "I-275 Expy", display: "I-275 Expy" },
  { value: "I-295 E", display: "I-295 E" },
  { value: "I-295 W", display: "I-295 W" },
  { value: "I-375", display: "I-375" },
  { value: "I-395", display: "I-395" },
  { value: "I-4", display: "I-4" },
  { value: "I-4 Express Lanes", display: "I-4 Express Lanes" },
  { value: "I-4 Westbound", display: "I-4 Westbound" },
  { value: "I-595", display: "I-595" },
  { value: "I-75", display: "I-75" },
  { value: "I-75 Express Lanes", display: "I-75 Express Lanes" },
  { value: "I-95", display: "I-95" },
  { value: "I-95 Northbound", display: "I-95 Northbound" },
  { value: "I-95 Southbound", display: "I-95 Southbound" },
  { value: "IMMOKALEE ROAD", display: "IMMOKALEE ROAD" },
  { value: "Indiantown Road", display: "Indiantown Road" },
  { value: "International Dr", display: "International Dr" },
  { value: "International Pkwy", display: "International Pkwy" },
  { value: "JACARANDA BLVD", display: "JACARANDA BLVD" },
  { value: "JOHN RINGLING CAUSEWAY", display: "JOHN RINGLING CAUSEWAY" },
  { value: "Jackson Ave", display: "Jackson Ave" },
  { value: "Jog Road", display: "Jog Road" },
  { value: "John Young Parkway", display: "John Young Parkway" },
  { value: "John Young Pkwy", display: "John Young Pkwy" },
  { value: "Kathleen Rd", display: "Kathleen Rd" },
  { value: "King St", display: "King St" },
  { value: "Kings Highway", display: "Kings Highway" },
  { value: "Kirkman Rd", display: "Kirkman Rd" },
  { value: "Krome Avenue", display: "Krome Avenue" },
  { value: "LAUREL RD", display: "LAUREL RD" },
  { value: "LPGA Blvd", display: "LPGA Blvd" },
  { value: "Lake Andrew Dr", display: "Lake Andrew Dr" },
  { value: "Lake City", display: "Lake City" },
  { value: "Lake Mary Blvd", display: "Lake Mary Blvd" },
  { value: "Lake Pickett Rd", display: "Lake Pickett Rd" },
  { value: "Lake Underhill Rd", display: "Lake Underhill Rd" },
  { value: "Landstar Blvd", display: "Landstar Blvd" },
  { value: "Landstreet Rd", display: "Landstreet Rd" },
  { value: "Lee Rd", display: "Lee Rd" },
  { value: "Leevista Blvd", display: "Leevista Blvd" },
  { value: "Lockwood Rd", display: "Lockwood Rd" },
  { value: "Luckett Road", display: "Luckett Road" },
  { value: "MLK Jr. Blvd", display: "MLK Jr. Blvd" },
  { value: "MacArthur Causeway", display: "MacArthur Causeway" },
  { value: "Mahan Dr", display: "Mahan Dr" },
  { value: "Main Street Bridge", display: "Main Street Bridge" },
  { value: "Maintenance", display: "Maintenance" },
  { value: "Maitland Blvd", display: "Maitland Blvd" },
  { value: "Malabar Rd", display: "Malabar Rd" },
  { value: "Manatee Ave", display: "Manatee Ave" },
  {
    value: "Martin Luther King Jr Pkwy",
    display: "Martin Luther King Jr Pkwy",
  },
  { value: "Mary Esther Blvd", display: "Mary Esther Blvd" },
  { value: "Mathews Bridge", display: "Mathews Bridge" },
  { value: "Mayport Road", display: "Mayport Road" },
  { value: "McCulloch Rd", display: "McCulloch Rd" },
  { value: "McGregor Blvd", display: "McGregor Blvd" },
  { value: "Metro Pkwy", display: "Metro Pkwy" },
  { value: "Metropolis Way", display: "Metropolis Way" },
  { value: "Miami Gardens DR", display: "Miami Gardens DR" },
  { value: "Miccosukee Rd", display: "Miccosukee Rd" },
  { value: "Michael G. Rippe Pkwy", display: "Michael G. Rippe Pkwy" },
  { value: "Michigan Ave", display: "Michigan Ave" },
  { value: "Michigan St", display: "Michigan St" },
  { value: "Midbay Bridge", display: "Midbay Bridge" },
  { value: "Military Trail", display: "Military Trail" },
  { value: "Mills Ave", display: "Mills Ave" },
  { value: "Minton Rd", display: "Minton Rd" },
  { value: "Mitchell Hammock Rd", display: "Mitchell Hammock Rd" },
  { value: "Moccasin Wallow", display: "Moccasin Wallow" },
  { value: "Monroe Rd", display: "Monroe Rd" },
  { value: "Montgomery Rd", display: "Montgomery Rd" },
  { value: "Mtchll Hmmck", display: "Mtchll Hmmck" },
  { value: "Murrell Rd", display: "Murrell Rd" },
  { value: "N Central Ave", display: "N Central Ave" },
  { value: "N Chickasaw Trl", display: "N Chickasaw Trl" },
  { value: "N Cocoa Blvd", display: "N Cocoa Blvd" },
  { value: "N Courtenay Pkwy", display: "N Courtenay Pkwy" },
  { value: "N Dean Rd", display: "N Dean Rd" },
  { value: "N Garland Ave", display: "N Garland Ave" },
  { value: "N Hiawassee Rd", display: "N Hiawassee Rd" },
  { value: "N Hughey Ave", display: "N Hughey Ave" },
  { value: "N John Young Pkwy", display: "N John Young Pkwy" },
  { value: "N Magnolia Ave", display: "N Magnolia Ave" },
  { value: "N Orange Blossom Trl", display: "N Orange Blossom Trl" },
  { value: "N Pine Hills Rd", display: "N Pine Hills Rd" },
  { value: "N Ronald Reagan Blvd", display: "N Ronald Reagan Blvd" },
  { value: "N US Highway 17", display: "N US Highway 17" },
  { value: "N US-1", display: "N US-1" },
  { value: "N US-27", display: "N US-27" },
  { value: "N Wickham Rd", display: "N Wickham Rd" },
  { value: "NE 100th St", display: "NE 100th St" },
  { value: "NE 70th St", display: "NE 70th St" },
  { value: "NE Waldo Rd", display: "NE Waldo Rd" },
  { value: "NORTH JONES LOOP RD", display: "NORTH JONES LOOP RD" },
  { value: "NW 39th Ave", display: "NW 39th Ave" },
  { value: "NW Gainesville Rd", display: "NW Gainesville Rd" },
  { value: "Narcoossee Rd", display: "Narcoossee Rd" },
  { value: "Narcoossee Road", display: "Narcoossee Road" },
  { value: "Nebraska Ave", display: "Nebraska Ave" },
  { value: "Neptune Rd", display: "Neptune Rd" },
  { value: "New Independence Pkwy", display: "New Independence Pkwy" },
  { value: "New York Ave", display: "New York Ave" },
  { value: "Newberry Rd", display: "Newberry Rd" },
  { value: "Nine Mile Rd", display: "Nine Mile Rd" },
  { value: "Northlake Blvd", display: "Northlake Blvd" },
  { value: "Nova Rd", display: "Nova Rd" },
  { value: "OTHER", display: "OTHER" },
  { value: "Oak Rd", display: "Oak Rd" },
  { value: "Oak Ridge Rd", display: "Oak Ridge Rd" },
  { value: "Oak St", display: "Oak St" },
  { value: "Oakland Park Boulevard", display: "Oakland Park Boulevard" },
  { value: "Ocean Shore Blvd", display: "Ocean Shore Blvd" },
  { value: "Ocean St", display: "Ocean St" },
  { value: "Ocoee Apopka Rd", display: "Ocoee Apopka Rd" },
  { value: "Okeechobee Boulevard", display: "Okeechobee Boulevard" },
  { value: "Old Lake Mary Rd", display: "Old Lake Mary Rd" },
  { value: "Old Lake Wilson Rd", display: "Old Lake Wilson Rd" },
  { value: "Old Winter Garden Rd", display: "Old Winter Garden Rd" },
  { value: "Orange Ave", display: "Orange Ave" },
  { value: "Orange Blossom Trl", display: "Orange Blossom Trl" },
  { value: "Orange Camp Rd", display: "Orange Camp Rd" },
  { value: "Orange Center Blvd", display: "Orange Center Blvd" },
  { value: "Orlando Ave", display: "Orlando Ave" },
  { value: "Osceola Parkway", display: "Osceola Parkway" },
  { value: "Osceola Polk Line Rd", display: "Osceola Polk Line Rd" },
  { value: "PGA Blvd", display: "PGA Blvd" },
  { value: "PINE RIDGE ROAD", display: "PINE RIDGE ROAD" },
  { value: "Palatka Memorial Bridge", display: "Palatka Memorial Bridge" },
  { value: "Palm Bay Rd", display: "Palm Bay Rd" },
  { value: "Palm Beach Blvd", display: "Palm Beach Blvd" },
  { value: "Palm Beach Lakes Blvd", display: "Palm Beach Lakes Blvd" },
  { value: "Palm Coast Pkwy", display: "Palm Coast Pkwy" },
  { value: "Palm Pkwy", display: "Palm Pkwy" },
  { value: "Pat Thomas Pkwy", display: "Pat Thomas Pkwy" },
  { value: "Pembroke Road", display: "Pembroke Road" },
  { value: "Pershing Ave", display: "Pershing Ave" },
  { value: "Philips Hwy", display: "Philips Hwy" },
  { value: "Pine Ave", display: "Pine Ave" },
  { value: "Pine Island Rd", display: "Pine Island Rd" },
  { value: "Pineda Cswy", display: "Pineda Cswy" },
  { value: "Pines Blvd", display: "Pines Blvd" },
  { value: "Pleasant Hill Rd", display: "Pleasant Hill Rd" },
  { value: "Poinciana Blvd", display: "Poinciana Blvd" },
  { value: "Polk Parkway", display: "Polk Parkway" },
  { value: "Port Miami Tunnel", display: "Port Miami Tunnel" },
  { value: "Port St John Pkwy", display: "Port St John Pkwy" },
  { value: "Post Rd", display: "Post Rd" },
  { value: "Princeton St", display: "Princeton St" },
  { value: "Quadrille Blvd", display: "Quadrille Blvd" },
  { value: "RIVER RD", display: "RIVER RD" },
  { value: "Racetrack Rd", display: "Racetrack Rd" },
  { value: "Ramond Diehl Rd", display: "Ramond Diehl Rd" },
  { value: "Red Bug Lake Rd", display: "Red Bug Lake Rd" },
  { value: "RedBug Lk Rd", display: "RedBug Lk Rd" },
  { value: "Reversible Lane - I595", display: "Reversible Lane - I595" },
  { value: "Rinehart Rd", display: "Rinehart Rd" },
  { value: "Rio Grande Ave", display: "Rio Grande Ave" },
  { value: "Riverside Ave", display: "Riverside Ave" },
  { value: "Robinson St", display: "Robinson St" },
  { value: "Ronald Reagan Blvd", display: "Ronald Reagan Blvd" },
  { value: "Rosalind Ave", display: "Rosalind Ave" },
  { value: "Rosalind Avenue", display: "Rosalind Avenue" },
  { value: "Rouse Rd", display: "Rouse Rd" },
  { value: "S Apopka Vineland Rd", display: "S Apopka Vineland Rd" },
  { value: "S Bumby Ave", display: "S Bumby Ave" },
  { value: "S Garland Ave", display: "S Garland Ave" },
  { value: "S Goldenrod Rd", display: "S Goldenrod Rd" },
  { value: "S Hughey Ave", display: "S Hughey Ave" },
  { value: "S John Young Pkwy", display: "S John Young Pkwy" },
  { value: "S Kirkman Rd", display: "S Kirkman Rd" },
  { value: "S Mills Ave", display: "S Mills Ave" },
  { value: "S Orange Ave", display: "S Orange Ave" },
  { value: "S Orange Blossom Trl", display: "S Orange Blossom Trl" },
  { value: "S Ridgewood Ave", display: "S Ridgewood Ave" },
  { value: "S River RD", display: "S River RD" },
  { value: "S Ronald Reagan Blvd", display: "S Ronald Reagan Blvd" },
  { value: "S US-17-92", display: "S US-17-92" },
  { value: "S US-27", display: "S US-27" },
  { value: "S Wickham Rd", display: "S Wickham Rd" },
  { value: "SE 58th Ave", display: "SE 58th Ave" },
  { value: "SE Highway 42", display: "SE Highway 42" },
  { value: "SE Maricamp Rd", display: "SE Maricamp Rd" },
  { value: "SIKES BLVD", display: "SIKES BLVD" },
  { value: "SR 408 Westbound", display: "SR 408 Westbound" },
  { value: "SR 66", display: "SR 66" },
  { value: "SR 7", display: "SR 7" },
  { value: "SR-10", display: "SR-10" },
  { value: "SR-100", display: "SR-100" },
  { value: "SR-109", display: "SR-109" },
  { value: "SR-11", display: "SR-11" },
  { value: "SR-112", display: "SR-112" },
  { value: "SR-115", display: "SR-115" },
  { value: "SR-12", display: "SR-12" },
  { value: "SR-121", display: "SR-121" },
  { value: "SR-13", display: "SR-13" },
  { value: "SR-15", display: "SR-15" },
  { value: "SR-152", display: "SR-152" },
  { value: "SR-15A", display: "SR-15A" },
  { value: "SR-16", display: "SR-16" },
  { value: "SR-17", display: "SR-17" },
  { value: "SR-19", display: "SR-19" },
  { value: "SR-20", display: "SR-20" },
  { value: "SR-200", display: "SR-200" },
  { value: "SR-202", display: "SR-202" },
  { value: "SR-21", display: "SR-21" },
  { value: "SR-22", display: "SR-22" },
  { value: "SR-222", display: "SR-222" },
  { value: "SR-23", display: "SR-23" },
  { value: "SR-24", display: "SR-24" },
  { value: "SR-26", display: "SR-26" },
  { value: "SR-261", display: "SR-261" },
  { value: "SR-263", display: "SR-263" },
  { value: "SR-267", display: "SR-267" },
  { value: "SR-273", display: "SR-273" },
  { value: "SR-276", display: "SR-276" },
  { value: "SR-281 (Avalon Blvd)", display: "SR-281 (Avalon Blvd)" },
  { value: "SR-285", display: "SR-285" },
  { value: "SR-29", display: "SR-29" },
  { value: "SR-291", display: "SR-291" },
  { value: "SR-293", display: "SR-293" },
  { value: "SR-295", display: "SR-295" },
  { value: "SR-296", display: "SR-296" },
  { value: "SR-297 (Pine Forrest Rd)", display: "SR-297 (Pine Forrest Rd)" },
  { value: "SR-3", display: "SR-3" },
  { value: "SR-31", display: "SR-31" },
  { value: "SR-326", display: "SR-326" },
  { value: "SR-33", display: "SR-33" },
  { value: "SR-331", display: "SR-331" },
  { value: "SR-35", display: "SR-35" },
  { value: "SR-363", display: "SR-363" },
  { value: "SR-37", display: "SR-37" },
  { value: "SR-373", display: "SR-373" },
  { value: "SR-399", display: "SR-399" },
  { value: "SR-4", display: "SR-4" },
  { value: "SR-40", display: "SR-40" },
  { value: "SR-400", display: "SR-400" },
  { value: "SR-404", display: "SR-404" },
  { value: "SR-405", display: "SR-405" },
  { value: "SR-406", display: "SR-406" },
  { value: "SR-407", display: "SR-407" },
  { value: "SR-408", display: "SR-408" },
  { value: "SR-414", display: "SR-414" },
  { value: "SR-415", display: "SR-415" },
  { value: "SR-416", display: "SR-416" },
  { value: "SR-417", display: "SR-417" },
  { value: "SR-419", display: "SR-419" },
  { value: "SR-421", display: "SR-421" },
  { value: "SR-423", display: "SR-423" },
  { value: "SR-424", display: "SR-424" },
  { value: "SR-426", display: "SR-426" },
  { value: "SR-429", display: "SR-429" },
  { value: "SR-429-TOLL Northbound", display: "SR-429-TOLL Northbound" },
  { value: "SR-434", display: "SR-434" },
  { value: "SR-435", display: "SR-435" },
  { value: "SR-436", display: "SR-436" },
  { value: "SR-438", display: "SR-438" },
  { value: "SR-44", display: "SR-44" },
  { value: "SR-451", display: "SR-451" },
  { value: "SR-453", display: "SR-453" },
  { value: "SR-46", display: "SR-46" },
  { value: "SR-464", display: "SR-464" },
  { value: "SR-471", display: "SR-471" },
  { value: "SR-472", display: "SR-472" },
  { value: "SR-482", display: "SR-482" },
  { value: "SR-50", display: "SR-50" },
  { value: "SR-501", display: "SR-501" },
  { value: "SR-507", display: "SR-507" },
  { value: "SR-508", display: "SR-508" },
  { value: "SR-514", display: "SR-514" },
  { value: "SR-518", display: "SR-518" },
  { value: "SR-519", display: "SR-519" },
  { value: "SR-52", display: "SR-52" },
  { value: "SR-520", display: "SR-520" },
  { value: "SR-524", display: "SR-524" },
  { value: "SR-526", display: "SR-526" },
  { value: "SR-527", display: "SR-527" },
  { value: "SR-528", display: "SR-528" },
  { value: "SR-535", display: "SR-535" },
  { value: "SR-536", display: "SR-536" },
  { value: "SR-538", display: "SR-538" },
  { value: "SR-539", display: "SR-539" },
  { value: "SR-54", display: "SR-54" },
  { value: "SR-540", display: "SR-540" },
  { value: "SR-542", display: "SR-542" },
  { value: "SR-544", display: "SR-544" },
  { value: "SR-549", display: "SR-549" },
  { value: "SR-551", display: "SR-551" },
  { value: "SR-552", display: "SR-552" },
  { value: "SR-56", display: "SR-56" },
  { value: "SR-563", display: "SR-563" },
  { value: "SR-568", display: "SR-568" },
  { value: "SR-572", display: "SR-572" },
  { value: "SR-574", display: "SR-574" },
  { value: "SR-580", display: "SR-580" },
  { value: "SR-589", display: "SR-589" },
  { value: "SR-59", display: "SR-59" },
  { value: "SR-5A", display: "SR-5A" },
  { value: "SR-60", display: "SR-60" },
  { value: "SR-600", display: "SR-600" },
  { value: "SR-61", display: "SR-61" },
  { value: "SR-618", display: "SR-618" },
  { value: "SR-62", display: "SR-62" },
  { value: "SR-64", display: "SR-64" },
  { value: "SR-674", display: "SR-674" },
  { value: "SR-684", display: "SR-684" },
  { value: "SR-688", display: "SR-688" },
  { value: "SR-69", display: "SR-69" },
  { value: "SR-694", display: "SR-694" },
  { value: "SR-70", display: "SR-70" },
  { value: "SR-71", display: "SR-71" },
  { value: "SR-72", display: "SR-72" },
  { value: "SR-73", display: "SR-73" },
  { value: "SR-739", display: "SR-739" },
  { value: "SR-758", display: "SR-758" },
  { value: "SR-77", display: "SR-77" },
  { value: "SR-776", display: "SR-776" },
  { value: "SR-777", display: "SR-777" },
  { value: "SR-78", display: "SR-78" },
  { value: "SR-780", display: "SR-780" },
  { value: "SR-789", display: "SR-789" },
  { value: "SR-79", display: "SR-79" },
  { value: "SR-80", display: "SR-80" },
  { value: "SR-81", display: "SR-81" },
  { value: "SR-82", display: "SR-82" },
  { value: "SR-826", display: "SR-826" },
  { value: "SR-836", display: "SR-836" },
  { value: "SR-84", display: "SR-84" },
  { value: "SR-842", display: "SR-842" },
  { value: "SR-85", display: "SR-85" },
  { value: "SR-860", display: "SR-860" },
  { value: "SR-865", display: "SR-865" },
  { value: "SR-867", display: "SR-867" },
  { value: "SR-87", display: "SR-87" },
  { value: "SR-874", display: "SR-874" },
  { value: "SR-878", display: "SR-878" },
  { value: "SR-884", display: "SR-884" },
  { value: "SR-9", display: "SR-9" },
  { value: "SR-924", display: "SR-924" },
  { value: "SR-951", display: "SR-951" },
  { value: "SR-997", display: "SR-997" },
  { value: "SR-9B", display: "SR-9B" },
  { value: "SR-A1A", display: "SR-A1A" },
  { value: "SUMTER BLVD", display: "SUMTER BLVD" },
  { value: "SW 10 St", display: "SW 10 St" },
  { value: "SW 10th St", display: "SW 10th St" },
  { value: "SW 13th St", display: "SW 13th St" },
  { value: "SW 66th St", display: "SW 66th St" },
  { value: "SW CR-484", display: "SW CR-484" },
  { value: "SW Highway 200", display: "SW Highway 200" },
  { value: "San Carlos Blvd", display: "San Carlos Blvd" },
  { value: "San Jose Blvd", display: "San Jose Blvd" },
  { value: "Sand Lake Rd", display: "Sand Lake Rd" },
  { value: "Sanibel Cswy", display: "Sanibel Cswy" },
  { value: "Sarno Rd", display: "Sarno Rd" },
  { value: "Sawgrass Expressway", display: "Sawgrass Expressway" },
  { value: "Saxon Blvd", display: "Saxon Blvd" },
  { value: "Semoran Blvd", display: "Semoran Blvd" },
  { value: "Silver Springs Blvd", display: "Silver Springs Blvd" },
  { value: "Silver Star Rd", display: "Silver Star Rd" },
  { value: "Simpson Rd", display: "Simpson Rd" },
  { value: "Southern Boulevard", display: "Southern Boulevard" },
  { value: "Southside Blvd", display: "Southside Blvd" },
  { value: "Sr-535", display: "Sr-535" },
  { value: "St Augustine", display: "St Augustine" },
  { value: "State Road 535", display: "State Road 535" },
  { value: "State Road 681", display: "State Road 681" },
  { value: "State Road 80", display: "State Road 80" },
  { value: "Stickney Point Rd", display: "Stickney Point Rd" },
  { value: "Sumter", display: "Sumter" },
  { value: "Suncoast Parkway", display: "Suncoast Parkway" },
  { value: "Sunrise Blvd", display: "Sunrise Blvd" },
  { value: "TOLEDO BLADE BLVD", display: "TOLEDO BLADE BLVD" },
  { value: "TUCKERS GRADE RD", display: "TUCKERS GRADE RD" },
  { value: "Taft Vineland Rd", display: "Taft Vineland Rd" },
  { value: "Terminal Access Rd", display: "Terminal Access Rd" },
  { value: "Thomasville Rd", display: "Thomasville Rd" },
  { value: "Tomoka Farms Rd", display: "Tomoka Farms Rd" },
  { value: "Tradeport Dr", display: "Tradeport Dr" },
  { value: "Truman Ave", display: "Truman Ave" },
  { value: "Turkey Lake Rd", display: "Turkey Lake Rd" },
  { value: "Tuskawilla Rd", display: "Tuskawilla Rd" },
  { value: "UNIVERSITY PARKWAY", display: "UNIVERSITY PARKWAY" },
  { value: "UNIVERSITY PKWY", display: "UNIVERSITY PKWY" },
  { value: "US 19", display: "US 19" },
  { value: "US Highway 17", display: "US Highway 17" },
  { value: "US-1", display: "US-1" },
  { value: "US-1 ALT", display: "US-1 ALT" },
  { value: "US-129", display: "US-129" },
  { value: "US-17", display: "US-17" },
  { value: "US-17-92", display: "US-17-92" },
  { value: "US-19", display: "US-19" },
  { value: "US-19 (SR-57)", display: "US-19 (SR-57)" },
  { value: "US-192", display: "US-192" },
  { value: "US-231", display: "US-231" },
  { value: "US-27", display: "US-27" },
  { value: "US-29", display: "US-29" },
  { value: "US-301", display: "US-301" },
  { value: "US-319", display: "US-319" },
  { value: "US-331", display: "US-331" },
  { value: "US-41", display: "US-41" },
  { value: "US-41 Business", display: "US-41 Business" },
  { value: "US-441", display: "US-441" },
  { value: "US-90", display: "US-90" },
  { value: "US-90 ALT", display: "US-90 ALT" },
  { value: "US-92", display: "US-92" },
  { value: "US-98", display: "US-98" },
  { value: "US301", display: "US301" },
  { value: "Universal Blvd", display: "Universal Blvd" },
  { value: "University Ave", display: "University Ave" },
  { value: "University Blvd", display: "University Blvd" },
  { value: "University Drive", display: "University Drive" },
  { value: "Veterans Expressway", display: "Veterans Expressway" },
  {
    value: "Veterans Expressway Express Lanes",
    display: "Veterans Expressway Express Lanes",
  },
  { value: "Viera Blvd", display: "Viera Blvd" },
  { value: "Vilano Bridge", display: "Vilano Bridge" },
  { value: "Vine St", display: "Vine St" },
  { value: "Vineland Ave", display: "Vineland Ave" },
  { value: "Vineland Rd", display: "Vineland Rd" },
  { value: "W 25th St", display: "W 25th St" },
  { value: "W 41st St", display: "W 41st St" },
  { value: "W Airport Blvd", display: "W Airport Blvd" },
  { value: "W Amelia St", display: "W Amelia St" },
  { value: "W Anderson St", display: "W Anderson St" },
  { value: "W Broadway St", display: "W Broadway St" },
  { value: "W Chapman Rd", display: "W Chapman Rd" },
  { value: "W Colonial Dr", display: "W Colonial Dr" },
  { value: "W Fairbanks Ave", display: "W Fairbanks Ave" },
  { value: "W Gore St", display: "W Gore St" },
  { value: "W Highway 326", display: "W Highway 326" },
  { value: "W Highway 40", display: "W Highway 40" },
  {
    value: "W International Speedway Blvd",
    display: "W International Speedway Blvd",
  },
  {
    value: "W Irlo Bronson Memorial Hwy",
    display: "W Irlo Bronson Memorial Hwy",
  },
  { value: "W Kaley Ave", display: "W Kaley Ave" },
  { value: "W King St", display: "W King St" },
  { value: "W Lancaster Rd", display: "W Lancaster Rd" },
  { value: "W Maitland Blvd", display: "W Maitland Blvd" },
  { value: "W Miracle Strip Pkwy", display: "W Miracle Strip Pkwy" },
  { value: "W Mitchell Hammock Rd", display: "W Mitchell Hammock Rd" },
  { value: "W Oak Ridge Rd", display: "W Oak Ridge Rd" },
  { value: "W Sand Lake Rd", display: "W Sand Lake Rd" },
  { value: "W South St", display: "W South St" },
  { value: "W Sr-46", display: "W Sr-46" },
  { value: "W Washington St", display: "W Washington St" },
  { value: "Wekiwa Springs Rd", display: "Wekiwa Springs Rd" },
  { value: "West Rd", display: "West Rd" },
  { value: "West Town Pkwy", display: "West Town Pkwy" },
  { value: "Westwood Blvd", display: "Westwood Blvd" },
  { value: "Wickham Rd", display: "Wickham Rd" },
  { value: "Williston Rd", display: "Williston Rd" },
  { value: "Winter Garden Vineland Rd", display: "Winter Garden Vineland Rd" },
  { value: "World Center Dr", display: "World Center Dr" },
  { value: "World Dr", display: "World Dr" },
  { value: "Wrights Rd", display: "Wrights Rd" },
];
