import React, { useEffect } from 'react';
import { GoogleMap, Marker, InfoWindow  } from '@react-google-maps/api';
import { useSelector } from 'react-redux';
import { getIncident } from '../../../Redux/Selectors';
import { camerasIdsCapturing } from './camerasToShow';

export const IncidentMap: React.FC<{}> = (): JSX.Element => {
	const incident = useSelector(getIncident);

  const [coordinatesAssociated, setCoordinatesAssociated] = React.useState<({ latitude: number; longitude: number; id: string; } | null)[]>([]);
  const [coordinatesDistance, setCoordinatesDistance] = React.useState<({ latitude: number; longitude: number; id: string; } | null)[]>([]);
  const [coordinatesNotCaptured, setCoordinatesNotCaptured] = React.useState<({ latitude: number; longitude: number; id: string; } | null)[]>([]);
  const [selectedMarkerIndex, setSelectedMarkerIndex] = React.useState<string | null>(null);
  const [idsToShow, setIdsToShow] = React.useState<string[]>(camerasIdsCapturing);

	const containerStyle = {
		width: '100%',
		height: '90vh',
    maxHeight: '1000px',
	};

  const closeInfoWindow = () => {
    setSelectedMarkerIndex(null);
  };

  useEffect(() => {
    const videoIds = Object.keys(incident.videos);
    setIdsToShow([...videoIds, ...camerasIdsCapturing]);

  }, [incident.videos]);

  useEffect(() => {
    const associatedCoordinates = incident.incidentInformation.associatedCameraId.filter(cameraId => idsToShow.includes(cameraId) && Object.keys(incident.videos).includes(cameraId)).map(cameraId => {
    if (incident.cameraInformation && cameraId in incident.cameraInformation) {
        return {
          latitude: incident.cameraInformation[cameraId].latitude,
          longitude: incident.cameraInformation[cameraId].longitude,
          id: cameraId
        };
      }
      return null;
    })

    setCoordinatesAssociated(associatedCoordinates);
  
    const distanceCoordinates = incident.incidentInformation.distanceCameraId.filter(cameraId => idsToShow.includes(cameraId) && Object.keys(incident.videos).includes(cameraId)).map(cameraId => {
      if (incident.cameraInformation && cameraId in incident.cameraInformation) {
        return {
          latitude: incident.cameraInformation[cameraId].latitude,
          longitude: incident.cameraInformation[cameraId].longitude,
          id: cameraId
        };
      }
      return { latitude: 0, longitude: 0, id: ''};
    })

    setCoordinatesDistance(distanceCoordinates);

    const notCapturedDistance = incident.incidentInformation.distanceCameraId.filter(cameraId => idsToShow.includes(cameraId) && !Object.keys(incident.videos).includes(cameraId)).map(cameraId => {
      if (incident.cameraInformation && cameraId in incident.cameraInformation) {
        return {
          latitude: incident.cameraInformation[cameraId].latitude,
          longitude: incident.cameraInformation[cameraId].longitude,
          id: cameraId
        };
      }
      return { latitude: 0, longitude: 0, id: ''};
    })

    const notCapturedAssociated = incident.incidentInformation.associatedCameraId.filter(cameraId => idsToShow.includes(cameraId) && !Object.keys(incident.videos).includes(cameraId)).map(cameraId => {
      if (incident.cameraInformation && cameraId in incident.cameraInformation) {
        return {
          latitude: incident.cameraInformation[cameraId].latitude,
          longitude: incident.cameraInformation[cameraId].longitude,
          id: cameraId
        };
      }
      return { latitude: 0, longitude: 0, id: ''};
    })

    setCoordinatesNotCaptured([...notCapturedDistance, ...notCapturedAssociated]);

  }, [idsToShow, incident.cameraInformation, incident.incidentInformation.associatedCameraId, incident.incidentInformation.distanceCameraId, incident.videos]);

  useEffect(() => {
  }, [])

	const commonIconConfig = {
    path: window.google.maps.SymbolPath.CIRCLE,
    scale: 7,
    fillOpacity: 1,
    strokeWeight: 0,
    labelOrigin: new window.google.maps.Point(0, -3)
	};

	const associatedIcon = {
    ...commonIconConfig,
    fillColor: 'red'
	};

	const distanceIcon = {
    ...commonIconConfig,
    fillColor: 'blue'
	};

  const notCapturedIcon = {
    ...commonIconConfig,
    fillColor: 'gray'
  };

	const handleActiveMarker = (marker: any) => {
    if (marker === selectedMarkerIndex) {
      return;
    }
    setSelectedMarkerIndex(marker);
  };

  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={{
        lat: incident.incidentInformation.latitude || 0,
        lng: incident.incidentInformation.longitude || 0,
      }}
      zoom={14}
    >
      {coordinatesAssociated.map((coord, index) => {
        if (coord === null) {
          return null;
        }
        if (!coord.latitude && !coord.longitude) {
          return null;
        }
        return (
          <div key={index}>
            <Marker 
              position={{ lat: coord.latitude || 0, lng: coord.longitude || 0 }}
              icon={associatedIcon}
              onClick={() => handleActiveMarker(coord.id)}
            />
            {selectedMarkerIndex === coord.id && (
              <InfoWindow
                position={{ lat: coord.latitude || 0, lng: coord.longitude || 0 }}
                onCloseClick={closeInfoWindow}
              >
                <div style={{ backgroundColor: "white", padding: "0 15px" }}>
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <img src={`https://fl511.com/map/Cctv/${coord.id}`} alt="Marker info" />
                  </div>
                  <h1 style={{ textAlign: "center" }}>{coord.id}</h1>
                  <table style={{ width: "100%" }}>
                    <tbody>
                    {Object.entries(incident.cameraInformation[coord.id]).map(([key, value]) => (
                      <tr key={key}>
                        <td style={{ textAlign: 'right', paddingRight: '10px', fontWeight: 'bold' }}>{key}:</td>
                        <td style={{  }}>{String(value)}</td>
                      </tr>
                    ))}
                    </tbody>
                  </table>
                </div>
              </InfoWindow>
            )}
          </div>
        );
      })}
      {coordinatesDistance.map((coord, index) => {
        if (coord === null) {
          return null;
        }
        if (!coord.latitude && !coord.longitude) {
          return null;
        }
        return (
          <div key={index}>
            <Marker 
              position={{ lat: coord.latitude || 0, lng: coord.longitude || 0 }}
              icon={distanceIcon}
              onClick={() => handleActiveMarker(coord.id)}
            />
            {selectedMarkerIndex === coord.id && (
              <InfoWindow
                position={{ lat: coord.latitude || 0, lng: coord.longitude || 0 }}
                onCloseClick={closeInfoWindow}
              >
                <div style={{ backgroundColor: "white", padding: "0 15px" }}>
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <img src={`https://fl511.com/map/Cctv/${coord.id}`} alt="Marker info" />
                  </div>
                  <h1 style={{ textAlign: "center" }}>{coord.id}</h1>
                  <table style={{ width: "100%" }}>
                    <tbody>
                    {Object.entries(incident.cameraInformation[coord.id]).map(([key, value]) => (
                      <tr key={key}>
                        <td style={{ textAlign: 'right', paddingRight: '10px', fontWeight: 'bold' }}>{key}:</td>
                        <td style={{  }}>{String(value)}</td>
                      </tr>
                    ))}
                    </tbody>
                  </table>
                </div>
              </InfoWindow>
            )}
          </div>
        );
      })}
      {coordinatesNotCaptured.map((coord, index) => {
        if (coord === null) {
          return null;
        }
        if (!coord.latitude && !coord.longitude) {
          return null;
        }
        return (
          <div key={index}>
            <Marker 
              position={{ lat: coord.latitude || 0, lng: coord.longitude || 0 }}
              icon={notCapturedIcon}
              onClick={() => handleActiveMarker(coord.id)}
            />
            {selectedMarkerIndex === coord.id && (
              <InfoWindow
                position={{ lat: coord.latitude || 0, lng: coord.longitude || 0 }}
                onCloseClick={closeInfoWindow}
              >
                <div style={{ backgroundColor: "white", padding: "0 15px" }}>
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <img src={`https://fl511.com/map/Cctv/${coord.id}`} alt="Marker info" />
                  </div>
                  <h1 style={{ textAlign: "center" }}>{coord.id}</h1>
                  <table style={{ width: "100%" }}>
                    <tbody>
                    {Object.entries(incident.cameraInformation[coord.id]).map(([key, value]) => (
                      <tr key={key}>
                        <td style={{ textAlign: 'right', paddingRight: '10px', fontWeight: 'bold' }}>{key}:</td>
                        <td style={{  }}>{String(value)}</td>
                      </tr>
                    ))}
                    </tbody>
                  </table>
                </div>
              </InfoWindow>
            )}
          </div>
        );
      })}
      <Marker
        position={{ lat: incident.incidentInformation.latitude || 0, lng: incident.incidentInformation.longitude || 0 }}
        label={{
          text: "Incident",
          color: "white",
          fontWeight: "bold",
          fontSize: "16px",
          className: "markerCustom"
        }}
        icon={{
          path: window.google.maps.SymbolPath.CIRCLE,
          scale: 10,
          fillOpacity: 1,
          strokeWeight: 0,
          fillColor: 'green',
          labelOrigin: new window.google.maps.Point(0, -2), // Adjust the position as needed
        }}
      />
    </GoogleMap>
  );
}
