import { State } from "./Reducer";

export const getOperationInProgress = (state: State) => state.operationInProgress;
export const getInLineOperationInProgress = (state: State) => state.inLineOperationInProgress;
export const getInInLineOperationInProgress = (state: State) => state.inInLineOperationInProgress;
export const getUserSelect = (state: State) => state.user;
export const getUserToken = (state: State) => state.user.userToken;
export const getFolders = (state: State) => state.folders;
export const getIncident = (state: State) => state.incident;
export const getIncidents = (state: State) => state.incidents;
export const getDataExplorer = (state: State) => state.dataExplorer;
export const getLoginTab = (state: State) => state.loginPageTab;
export const getIncidentMapInput = (state: State) => state.incidentMapInput;
export const getCamera = (state: State) => state.camera;