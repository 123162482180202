import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { BaseProps } from './Models/Props';
import { Home } from './Pages/Home/Home';
import { FileExplorer } from './Pages/FileExplorer/FileExplorer';
import { createUseStyles } from 'react-jss';
import { Theme } from './Styling/Theme';
import { IncidentsFL511 } from './Pages/incidents/fl511/DataExplorer';
import { Camera } from './Pages/Camera/Camera';
import { applicationEnv } from './Utils/Fetch';
import { IncidentsNY511 } from './Pages/incidents/ny511/Incidents';
import { IncidentFL511 } from './Pages/Incident/fl511/Incident';
import { IncidentNY511 } from './Pages/Incident/ny511/Incident';

const useStyles = createUseStyles((theme: Theme) => {
	return {
		content: {
			backgroundColor: theme.backgroundThird,
			color: theme.textPrimary,
			height: '100%',
			position: 'relative',
		},
	}
});


export const ComponentRouting: React.FC<BaseProps> = ({ dispatch, darkMode }) => {
	const classes = useStyles();

	return (
		<div className={classes.content}>
			<Routes>
				{applicationEnv === 'fl511' && (
					<>
						<Route path="/" element={<Home dispatch={dispatch} darkMode={darkMode} />} />
						<Route path="/file-explorer" element={<FileExplorer dispatch={dispatch} darkMode={darkMode} />} />
						<Route path="/incidents" element={<IncidentsFL511 dispatch={dispatch} />} />
						<Route path="/incident" element={<IncidentFL511 dispatch={dispatch} darkMode={darkMode} />} />
						<Route path="/camera" element={<Camera dispatch={dispatch} darkMode={darkMode} />} />
					</>
				)}
				{applicationEnv === 'ny511' && (
					<>
						<Route path="/" element={<Home dispatch={dispatch} darkMode={darkMode} />} />
						<Route path="/file-explorer" element={<FileExplorer dispatch={dispatch} darkMode={darkMode} />} />
						<Route path="/incidents" element={<IncidentsNY511 dispatch={dispatch} />} />
						<Route path="/incident" element={<IncidentNY511 dispatch={dispatch} darkMode={darkMode} />} />
						<Route path="/camera" element={<Camera dispatch={dispatch} darkMode={darkMode} />} />
					</>
				)}
			</Routes>
		</div>
	);
}
