import React from "react";
import { BaseProps } from "../../Models/Props";


export const Home: React.FC<BaseProps> = ({ dispatch, darkMode }): JSX.Element => {
	

	return (
		<>
		</>
	);
}