import React, { useEffect, useState } from "react";
import ReactPaginate from 'react-paginate';
import { DispatchProps } from "../../../../Models/Props";
import { useSelector } from "react-redux";
import { getDataExplorer } from "../../../../Redux/Selectors";
import { SET_DATA_EXPLORER_OFFSET_PAGE } from "../../../../Redux/Actions";
import { createUseStyles } from "react-jss";
import { Theme } from "../../../../Styling/Theme";
import "./pagination.css";
import { Paper, Skeleton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";

const useStyles = createUseStyles((theme: Theme) => {
	return {
		pagination: {
			"& .pagination": {
				"& .selected": {
					backgroundColor: theme.buttonMUI,
					"& a": {
						color: theme.textMUI,
						"&:hover": {
							backgroundColor: theme.buttonHoverMUI,
						},
					},
					"@media (max-width: 450px)": {
						marginRight: 30,
					},
				},
				"& :not(.selected):not(:first-child):not(:last-child)": {
					"& a": {
						"&:hover": {
							color: theme.textMUI,
							backgroundColor: theme.buttonMUI,
						},
					},
				},
				"& li": {
					"&:last-child": {
						backgroundColor: theme.buttonMUI,
						color: `${theme.textMUI} !important`,
						"& a": {
							"&:hover": {
								backgroundColor: theme.buttonHoverMUI,
							},
						},
					},
					"&:first-child": {
						backgroundColor: theme.buttonMUI,
						color: `${theme.textMUI} !important`,
						"& a": {
							"&:hover": {
								backgroundColor: theme.buttonHoverMUI,
							},
						},
					},
				},
			},
		},
		container: {
			position: "relative",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
		},
		leftDiv: {
			position: "absolute",
			top: "50%",
			left: 0,
			transform: "translateY(-50%)",
			marginRight: 8,
			marginLeft: 8,
		},
		rightDiv: {
			position: "absolute",
			top: "50%",
			right: 0,
			transform: "translateY(-50%)",
			marginLeft: 8,
			marginRight: 8,
		},
		centerDiv: {
			margin: "0 4px",
		},
		pageCount: {
			textAlign: "center",
			marginTop: -20,
			paddingBottom: 1,
		}
	}
});

export const Pagination: React.FC<DispatchProps> = ({ dispatch }): JSX.Element => {
	const dataExplorer = useSelector(getDataExplorer);

	const classes = useStyles();

	const handlePageClick = (event: { selected: number; }) => {
		const newOffset = (event.selected * dataExplorer.limit) % dataExplorer.count;
		dispatch({ type: SET_DATA_EXPLORER_OFFSET_PAGE, payload: { offset: newOffset, page: event.selected + 1 } });
	};

	const [width, setWidth] = useState(window.innerWidth);
	const [pageDisplay, setPageDisplay] = useState({page: 3, margin: 3});

	useEffect(() => {
		const handleResize = () => setWidth(window.innerWidth);
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	useEffect(() => {
		switch (true) {
			case (width < 450):
				setPageDisplay({page: 0, margin: 0});
				break;
			case (width < 800):
				setPageDisplay({page: 1, margin: 1});
				break;
			case (width < 920):
				setPageDisplay({page: 2, margin: 1});
				break
			case (width < 1000):
				setPageDisplay({page: 2, margin: 2});
				break;
			case (width > 1000):
				setPageDisplay({page: 3, margin: 3});
				break;
			default:
				setPageDisplay({page: 3, margin: 3});
				break;
		}
	}, [width]);

	return (
		<Paper sx={{ margin: "10px 0", position: "relative"}} className={classes.pagination}>
			<div className={"pagination-controls"}>
				{dataExplorer.pageCount !== 0 && <>
					<ReactPaginate
						breakLabel="..."
						nextLabel={<>Next <FontAwesomeIcon icon={faAngleRight} /></>}
						onPageChange={handlePageClick}
						pageRangeDisplayed={pageDisplay.page}
						marginPagesDisplayed={pageDisplay.margin}
						pageCount={dataExplorer.pageCount}
						previousLabel={<><FontAwesomeIcon icon={faAngleLeft} /> Previous</>}
						renderOnZeroPageCount={null}
						className={"pagination"}
						forcePage={dataExplorer.page - 1}
					/>
					</>
				}
				{!dataExplorer.pageCount &&
					<div className={classes.container}>
						<div className={classes.leftDiv}>
							<Skeleton
								variant="rectangular"
								width={110}
								height={35}
								sx={{ borderRadius: 1 }}
							/>
						</div>
						{Array.from({ length: 7 }).map((_, index) => (
							<div key={index} className={classes.centerDiv}>
								<Skeleton
									variant="rectangular"
									width={40}
									height={40}
									sx={{ borderRadius: 1 }}
								/>
							</div>
						))}
						<div className={classes.rightDiv}>
							<Skeleton
								variant="rectangular"
								width={80}
								height={35}
								sx={{ borderRadius: 1 }}
							/>
						</div>
					</div>
				}
			</div>
			<div className={classes.pageCount}>
				<p>{dataExplorer.count} Incidents</p>
			</div>
		</Paper>
	);
};