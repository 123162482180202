import React from "react";
import { BaseProps } from "../../Models/Props";
import { createUseStyles } from "react-jss";
import { Button, ButtonGroup, Card, FormControl, FormGroup, InputAdornment, InputLabel, OutlinedInput, TextField } from "@mui/material";
import { Theme } from "../../Styling/Theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { useSelector } from "react-redux";
import { getLoginTab, getUserSelect } from "../../Redux/Selectors";
import { FORGOT_PASSWORD, FORGOT_PASSWORD_SUBMIT, LOG_IN, SET_CONFIRMATION_CODE, SET_CONFIRM_NEW_PASSWORD, SET_EMAIL, SET_LOGIN_TAB, SET_NEW_PASSWORD, SET_USER, SIGN_UP } from "../../Redux/Actions";
import toast from "react-hot-toast";

const useStyles = createUseStyles((theme: Theme) => {
	return {
		wrapper: {
			display: "flex",
			flexDirection: "column",
			width: "100%",
			alignItems: "center",
			justifyContent: "center",
		},
		card: {
			width: 500,
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			"@media (max-width: 550px)": {
				width: "95%",
			},
		},
		header: {
			fontSize: 25,
			fontWeight: 400,
			marginTop: 50,
		},
		form: {
			width: "80%",
			marginTop: 50,
		},
		icon: {
			cursor: "pointer",
		},
		pageActive: {
			backgroundColor: "#edf0f2",
		},
		formInner: {
			width: "100%",
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
		}
	}
});

export const Login: React.FC<BaseProps> = ({ dispatch }): JSX.Element => {
	const [showPassword, setShowPassword] = React.useState(false);
	const user = useSelector(getUserSelect);
	const page = useSelector(getLoginTab);
	
	const classes = useStyles();

	const setPage = (page: string) => {
		dispatch({ type: SET_LOGIN_TAB, payload: page });
	}

	return (
		<div className={classes.wrapper}>
			<Card className={classes.card}>
				<ButtonGroup
					aria-label="text primary button group"
					sx={{ width: "100%", border: "none" }}
				>
					<Button sx={{ width: "50%", border: "none", backgroundColor: page === "login" ? "" : "#edf0f2" }} onClick={() => setPage("login")}>Login</Button>
					<Button sx={{ width: "50%", border: "none", borderRadius: 0, backgroundColor: page === "register" ? "" : "#edf0f2" }} onClick={() => setPage("register")}>Register</Button>
				</ButtonGroup>
				{page === "login" &&
					<>
					<h1 className={classes.header}>Sign In To District 5</h1>
					<FormGroup className={classes.form}>
						<TextField
							label="Email"
							variant="outlined"
							onChange={(e) =>
								dispatch({ type: SET_USER, payload: { ...user, manage: { ...user.manage, email: e.target.value }}})
							}
						/>
						<FormControl variant="outlined"
							sx={{ mt: 1 }}
						>
							<InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
							<OutlinedInput
								id="outlined-adornment-password"
								type={showPassword ? 'text' : 'password'}
								endAdornment={
									<InputAdornment position="end">
										<FontAwesomeIcon className={classes.icon} icon={showPassword ? faEye : faEyeSlash} onClick={() => setShowPassword(!showPassword)} />
									</InputAdornment>
								}
								autoComplete="on"
								label="Password"
								onChange={(e) =>
									dispatch({ type: SET_USER, payload: { ...user, manage: { ...user.manage, password: e.target.value }}})
								}
							/>
						</FormControl>
						<Button
							type="submit"
							fullWidth
							variant="contained"
							sx={{ mt: 3 }}
							onClick={() => dispatch({ type: LOG_IN, payload: user })}
						>
							Sign In
						</Button>
						<Button
							type="submit"
							fullWidth
							variant="text"
							sx={{ mt: 1, mb: 10 }}
							onClick={() => setPage("forgot")}
						>
							Forgot Password?
						</Button>
					</FormGroup>
					</>
				}
				{page === "register" &&
					<>
					<h1 className={classes.header}>Register For District 5</h1>
					<FormGroup className={classes.form}>
						<TextField
							label="Email"
							variant="outlined"
							onChange={(e) =>
								dispatch({ type: SET_USER, payload: { ...user, manage: { ...user.manage, email: e.target.value }}})
							}
						/>
						<TextField
							label="Full Name"
							variant="outlined"
							sx={{ mt: 1 }}
							onChange={(e) =>
								dispatch({ type: SET_USER, payload: { ...user, manage: { ...user.manage, name: e.target.value }}})
							}
						/>
						<FormControl variant="outlined"
							sx={{ mt: 1 }}
						>
							<InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
							<OutlinedInput
								id="outlined-adornment-password"
								type={showPassword ? 'text' : 'password'}
								endAdornment={
									<InputAdornment position="end">
										<FontAwesomeIcon className={classes.icon} icon={showPassword ? faEye : faEyeSlash} onClick={() => setShowPassword(!showPassword)} />
									</InputAdornment>
								}
								label="Password"
								autoComplete="on"
								onChange={(e) =>
									dispatch({ type: SET_USER, payload: { ...user, manage: { ...user.manage, password: e.target.value }}})
								}
							/>
						</FormControl>
						<Button
							type="submit"
							fullWidth
							variant="contained"
							sx={{ mt: 3, mb: 10 }}
							onClick={() => dispatch({ type: SIGN_UP, payload: user })}
						>
							Register
						</Button>
					</FormGroup>
					</>
				}
				{page === "forgot" &&
					<>
					<h1 className={classes.header}>Reset Password</h1>
					<FormGroup className={classes.form}>
						<TextField
							label="Email"
							variant="outlined"
							onChange={(e) =>
								dispatch({ type: SET_EMAIL, payload: e.target.value })
							}
						/>
						{!user.forgotPassword.confirmationCodeSent &&
							<Button
								fullWidth
								variant="contained"
								sx={{ mt: 3, mb: 10 }}
								onClick={() => dispatch({ type: FORGOT_PASSWORD, payload: {} })}
							>
								Send Confirmation Code
							</Button>
						}
						{user.forgotPassword.confirmationCodeSent &&
						<>
							<TextField
								label="Verification Code"
								variant="outlined"
								onChange={(e) =>
									dispatch({ type: SET_CONFIRMATION_CODE, payload: e.target.value })
								}
								sx={{ mt: 1 }}
							/>
							{/* Create two text fields, one for password and one for confirm password */}
							<FormControl variant="outlined"
								sx={{ mt: 1 }}
							>
								<InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
								<OutlinedInput
									type={showPassword ? 'text' : 'password'}
									endAdornment={
										<InputAdornment position="end">
											<FontAwesomeIcon className={classes.icon} icon={showPassword ? faEye : faEyeSlash} onClick={() => setShowPassword(!showPassword)} />
										</InputAdornment>
									}
									label="Password"
									autoComplete="on"
									onChange={(e) =>
										dispatch({ type: SET_NEW_PASSWORD, payload: e.target.value })
									}
								/>
							</FormControl>
							<FormControl variant="outlined"
								sx={{ mt: 1 }}
							>
								<InputLabel htmlFor="outlined-adornment-password">Confirm Password</InputLabel>
								<OutlinedInput
									type={showPassword ? 'text' : 'password'}
									endAdornment={
										<InputAdornment position="end">
											<FontAwesomeIcon className={classes.icon} icon={showPassword ? faEye : faEyeSlash} onClick={() => setShowPassword(!showPassword)} />
										</InputAdornment>
									}
									label="Confirm Password"
									autoComplete="on"
									onChange={(e) =>
										dispatch({ type: SET_CONFIRM_NEW_PASSWORD, payload: e.target.value })
									}
								/>
							</FormControl>
							<Button
								type="submit"
								fullWidth
								variant="contained"
								sx={{ mt: 3, mb: 10 }}
								onClick={() => {
									if (user.forgotPassword.newPassword === user.forgotPassword.confirmNewPassword) {
										dispatch({ type: FORGOT_PASSWORD_SUBMIT, payload: {} })
									} else {
										toast.error("Passwords do not match");
									}
								}}
							>
								Reset Password
							</Button>
						</>
						}
					</FormGroup>
					</>
				}
			</Card>
		</div>
	);
}