export interface Theme {
    backgroundPrimary: string;
    backgroundSecondary: string;
    backgroundThird: string;

    textPrimary: string;
    textSecondary: string;
    textMUI: string;
    border: string;
    sidebarBorder: string;

    borderMUI: string;

    inputBackgroundColor: string;
    inputTextColor: string;

    iconBlueColor: string;

    buttonPrimaryColor: string;
    buttonMUI: string;
    buttonHoverMUI: string;

    dividerBorder: string;
    dividerColor: string;
}

export const initializeTheme = (darkMode: boolean = false) => {
    return {
        backgroundPrimary: darkMode ? '#0B2948' : '#ffffff',
        backgroundSecondary: darkMode ? '#00458b' : '#edf0f2',
        backgroundThird: darkMode ? '#00213F' : '#edf0f2',

        textPrimary: darkMode ? "lightgray" : "black",
        textSecondary: darkMode ? "gray" : "dimgray",
        textMUI: darkMode ? "black" : "white",
        border: darkMode ? "#0B2948" : "lightgray",
        sidebarBorder: darkMode ? "0B2948" : "lightgray",

        borderMUI: darkMode ? "#4F627A" : "#C4C4C4",

        inputBackgroundColor: darkMode ? "#212529" : "#f1f5f8",
        inputTextColor: darkMode ? "white" : "black",

        iconBlueColor: "#1876D1",

        buttonPrimaryColor: "#1876D1",
        buttonMUI: darkMode ? "#8FC9F9" : "#1876D1",
        buttonHoverMUI: darkMode ? "#42a5f5" : "#1565c0",

        dividerBorder: darkMode ? "" : "rgba(0, 0, 0, 0.12)",
        dividerColor: darkMode ? "gray" : "",
    } as Theme;
}