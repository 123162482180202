import React, { useEffect, useState } from "react";
import { BaseProps } from "../../../Models/Props";
import { GET_INCIDENT, GET_INCIDENT_ADDITIONAL, SET_INCIDENT } from "../../../Redux/Actions";
import { createUseStyles } from "react-jss";
import { Theme } from "../../../Styling/Theme";
import { useSelector } from "react-redux";
import { getInInLineOperationInProgress, getInLineOperationInProgress, getIncident } from "../../../Redux/Selectors";
import { Player } from 'video-react';
import { Skeleton } from "@mui/material";
import { useJsApiLoader } from '@react-google-maps/api';
import { IncidentMap } from "./incidentMap";
import { initialState } from "../../../Redux/Reducer";
import StatusBar from "./notification";

const mapping: { [key: string]: string } = {
	"id": "Case ID",
	"roadwayName": "Roadway Name",
	"eventSubType": "Event Sub Type",
	"startTime": "Start Time",
	"lastUpdated": "Last Updated",
	"county": "County",
	"region": "Region",
	"severity": "Severity",
	"direction": "Direction",
	"latitude": "Latitude",
	"longitude": "Longitude",
	"associatedCameraId": "Associated Cameras",
	"distanceCameraId": "Distance Cameras",
	"description": "Description",
};


const useStyles = createUseStyles((theme: Theme) => {
	return {
		wrapper: {
			padding: 20,
			paddingTop: 5,
			"@media (max-width: 470px)": {
				textAlign: "center",
			},
		},
		wrapperCard: {
			backgroundColor: theme.backgroundPrimary,
			padding: "10px 20px",
			borderRadius: 10,
			marginBottom: 10,
		},
		incidentInformationGrid: {
			display: "grid",
			gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
			gridGap: 20,
			"& label": {
				fontWeight: "bold",
			},
			"@media (max-width: 470px)": {
				gridTemplateColumns: "1fr", // One column on mobile
			},
		},
		descriptionContainer: {
			gridColumn: "span 2", // This makes the description take up 2 columns
			"& label": {
				fontWeight: "bold",
			},
			"@media (max-width: 470px)": {
				gridColumn: "span 1", // Span one column on mobile
			},
		},
		incidentInformationDescription: {
			width: 400,
			marginTop: 20,
			"& label": {
				fontWeight: "bold",
			},
			"@media (max-width: 470px)": {
				width: "100%",
			},
		},
		videosGrid: {
			gridTemplateColumns: "repeat(auto-fit, minmax(350px, 1fr))",
			gridGap: 10,
			display: "flex",
			flexWrap: "wrap",
			justifyContent: "flex-start",
			alignItems: "flex-start",
			marginTop: -5,
		},
		video: {
			marginBottom: 10,
			marginTop: -5,
			"@media (min-width: 2500px)": {
				marginRight: 10,
			},
			width: "100%",
			cursor: "pointer",
			maxWidth: "20vw",
			"@media (max-width: 1500px)": {
				maxWidth: "20vw",
			},
			"@media (max-width: 1100px)": {
				maxWidth: "25vw",
			},
			"@media (max-width: 700px)": {
				maxWidth: "40vw",
				margin: "0 auto",
			},
			"@media (max-width: 600px)": {
				maxWidth: "80vw",
				margin: "0 auto",
			},
		},
		videoPlayer: {
			width: "100%",
			cursor: "pointer",
			maxWidth: 400,
		},
		skeletonLoader: {
			height: 0,
			width: "100%",
			paddingTop: "100%",
			position: "relative"
		},
		videoLoader: {
			position: "absolute",
			top: 20,
			width: "275px",
			height: "50%",
		},
		videoDetails: {
			display: "flex",
			flexDirection: "column",
			position: "relative",
		},
		videoDetailsSettings: {
			position: "absolute",
			top: 10,
			right: 0,
			cursor: "pointer",
		},
		skeletonCenter: {
			"@media (max-width: 470px)": {
				margin: "0 auto",
			},
		},
		videoSkeletonText: {
			"@media (max-width: 470px)": {
				margin: "-140px auto 0 auto",
			},
			marginTop: "-45%",
		},
		videoColumns: {
			display: 'flex',
			justifyContent: 'space-between',
			// less than 1100px display columns vertically
			"@media (max-width: 1100px)": {
				flexDirection: 'column',
			},
		},
		videoColumn: {
			flex: '1 1 50%',
			padding: '0 10px', // add some space between columns
		},
		popup: {
			backgroundColor: 'white',
			padding: '15px',
			position: 'absolute',
			zIndex: 2,
			width: '500px', // adjust as per requirement
			boxShadow: '0px 0px 10px 2px #0000001a',
			marginTop: "-25px",
			borderRadius: '5px',
			height: '600px',
			overflowY: 'scroll',
			"@media (max-width: 700px)": {
				display: 'none',
			},
		},
		arrow: {
			position: 'absolute',
			bottom: '-10px', // adjust as per requirement
			left: '50%',
			transform: 'translateX(-50%)',
			borderLeft: '10px solid transparent',
			borderRight: '10px solid transparent',
			borderTop: '10px solid white', // same color as the popup backgroundColor
		},
		container: {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
			'@media (max-width: 839px)': {
				flexDirection: 'column',
				alignItems: 'flex-start',
			},
		},
		flexCenter: {
			display: 'flex',
			alignItems: 'center',
			'@media (max-width: 839px)': {
				flexDirection: 'column',
				alignItems: 'center', // To center-align the square and the text in the middle under 840px
				display: "none"
			},
		},
		marginRight10: {
			marginRight: '10px',
			'@media (max-width: 839px)': {
				marginRight: '0px',
				marginBottom: '10px',
			},
		},
		legend: {
			marginRight: '5px',
			borderRadius: '20%',
			width: '15px',
			height: '15px',
			border: '1px solid',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
		associatedCameras: {
			backgroundColor: 'rgba(255, 0, 0, 0.5)',
			borderColor: 'red',
		},
		distanceCameras: {
			backgroundColor: 'rgba(0, 0, 255, 0.5)',
			borderColor: 'blue',
		},
		incident: {
			backgroundColor: 'rgba(0, 255, 0, 0.5)',
			borderColor: 'green',
		},
		notCapturedCameras: {
			backgroundColor: 'rgba(128, 128, 128, 0.5)',
			borderColor: 'gray',
		},
	};
});


export const IncidentFL511: React.FC<BaseProps> = ({ dispatch }): JSX.Element => {
	const incident = useSelector(getIncident);
	const inLineOperationInProgress = useSelector(getInLineOperationInProgress);
  const inInLineOperationInProgress = useSelector(getInInLineOperationInProgress);

	const [showPopup, setShowPopup] = useState(false);
	const [selectedId, setSelectedId] = useState("");

	const classes = useStyles();

	useEffect(() => {
		window.scrollTo(0, 0);
		const urlParams = new URLSearchParams(window.location.search);
		dispatch({ type: GET_INCIDENT, payload: urlParams.get("id") });
	}, [dispatch]);

	const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyBinliaB6m52YE5yxDxCp5dxjt3MSIekSg"
  })

	useEffect(() => {
		if (incident.incidentInformation && incident.incidentInformation.id) {
			document.title = `Incident: ${incident.incidentInformation.id}`;

			dispatch({ type: GET_INCIDENT_ADDITIONAL, payload: {} });
		}
	},[dispatch, incident.incidentInformation]);

	useEffect(() => {
		return () => {
			document.title = "Incident";
			dispatch({ type: SET_INCIDENT, payload: initialState.incident });
		};
	}, [dispatch]);

	const renderVideosForIds = (ids: string[]) => {
		return ids.map(id => {
			if (incident.videos[id]) {
				return (
					<div key={id}>
						<div>
							<h3>
								<span
									onMouseOver={() => { setShowPopup(true); setSelectedId(id); }}
									onMouseOut={() => setShowPopup(false)}
								>
									{id}
								</span>
							</h3>
						</div>
						{showPopup && id === selectedId && (
            <div className={classes.popup}
							onMouseOver={() => { setShowPopup(true); setSelectedId(id); }}
							onMouseOut={() => setShowPopup(false)}
						>
              <div className={classes.arrow} />
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <img src={`https://fl511.com/map/Cctv/${id}`} alt="Marker info" />
              </div>
              <h1 style={{ textAlign: "center" }}>{id}</h1>
              <table style={{ width: "100%" }}>
                <tbody>
									{incident.cameraInformation && id in incident.cameraInformation ? (
										Object.entries(incident.cameraInformation[id] || {}).map(([key, value]) => (
                    <tr key={key}>
                      <td style={{ textAlign: 'right', paddingRight: '10px', fontWeight: 'bold' }}>{key}:</td>
                      <td>{String(value)}</td>
                    </tr>
										))) : null
									}
                </tbody>
              </table>
            </div>
          )}
						<div className={classes.videosGrid}>
							{incident.videos[id].map((video: { name: string, url: string }) => (
								<div key={video.name} className={classes.video}>
									<Player playsInline preload="metadata" src={video.url} />
									<div className={classes.videoDetails}>
										<p>{convertToFormattedTime(video.name)}</p>
									</div>
								</div>
							))}
						</div>
					</div>
				);
			}
			return null;
		});
	};

	return (
		<>
			{!inLineOperationInProgress && incident.incidentInformation &&
				<>
				{new Date(incident.incidentInformation.startTime) < new Date(new Date().setMonth(new Date().getMonth() - 3)) && (
					<StatusBar incident={incident} dispatch={dispatch} />
				)}
				<div className={classes.wrapper}>
					<h1>{incident.incidentInformation.id}</h1>
					<div className={classes.wrapperCard}>
						<h2>Incident Information</h2>
						<div className={classes.incidentInformationGrid}>
							{Object.keys(incident.incidentInformation).map((key: string) => {
								if (!(key in mapping)) return null;
								let value = incident.incidentInformation[key];

								if (Array.isArray(value)) {
									if (value.length > 8) {
										value = value.slice(0, 8).join(", ") + ", ...";
									} else {
										value = value.join(", ");
									}
								}

								if (key === "startTime" || key === "lastUpdated") {
									value = convertDate(value);
								}

								return (
									<div key={key} className={key === "description" ? classes.descriptionContainer : undefined}>
										<label>{labelFixer(key)}</label>
										<p>{value}</p>
									</div>
								);
							})}
						</div>
					</div>
					<div className={classes.wrapperCard}>
						<div className={classes.container}>
							<h2>Map</h2>
							<div className={classes.flexCenter}>
								<div className={`${classes.flexCenter} ${classes.marginRight10}`}>
									<div className={`${classes.legend} ${classes.associatedCameras}`} />
									Associated Cameras
								</div>
								<div className={`${classes.flexCenter} ${classes.marginRight10}`}>
									<div className={`${classes.legend} ${classes.distanceCameras}`} />
									Distance Cameras
								</div>
								<div className={`${classes.flexCenter} ${classes.marginRight10}`}>
									<div className={`${classes.legend} ${classes.incident}`} />
									Incident
								</div>
								<div className={classes.flexCenter}>
									<div className={`${classes.legend} ${classes.notCapturedCameras}`} />
									Not Captured
								</div>
							</div>
						</div>
						{inInLineOperationInProgress && (
							<Skeleton variant="rectangular" width={"100%"} height={400} />
						)}
						{!inInLineOperationInProgress && isLoaded && Object.keys(incident.incidentInformation).length > 0 && (
							<IncidentMap />
						)}
					</div>
					<div className={classes.wrapperCard}>
						<h1>Videos</h1>
						<div className={classes.videoColumns}>
							<div className={classes.videoColumn}>
								<h2>Incident Associated Cameras</h2>
								{renderVideosForIds(incident.incidentInformation.associatedCameraId)}
							</div>
							<div className={classes.videoColumn}>
								<h2>Incident Distance Cameras</h2>
								{renderVideosForIds(incident.incidentInformation.distanceCameraId)}
							</div>
						</div>
					</div>
				</div>
				</>
			}
			{inLineOperationInProgress &&
				<div className={classes.wrapper}>
					<Skeleton variant="text" width={200} height={80} className={classes.skeletonCenter} />
					<div className={classes.wrapperCard}>
						<Skeleton variant="text" width={200} height={50} className={classes.skeletonCenter} />
						<div className={classes.incidentInformationGrid}>
							{[...Array(15)].map((_, index) => (
								<div key={index}>
									<Skeleton variant="text" width={80} height={30} className={classes.skeletonCenter} />
									<Skeleton variant="text" width={125} height={30} className={classes.skeletonCenter} />
								</div>
							))}
						</div>
					</div>
					<div className={classes.wrapperCard}>
						<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
							<h2>Map</h2>
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<div style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
									<div 
										style={{
											width: '15px',
											height: '15px',
											border: '1px solid red',
											backgroundColor: 'rgba(255, 0, 0, 0.5)',
											marginRight: '5px',
											borderRadius: '20%'
										}}
									/>
									Associated Cameras
								</div>
								<div style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
									<div 
										style={{
											width: '15px',
											height: '15px',
											border: '1px solid blue',
											backgroundColor: 'rgba(0, 0, 255, 0.5)',
											marginRight: '5px',
											borderRadius: '20%'
										}}
									/>
									Distance Cameras
								</div>
								<div style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
									<div 
										style={{
											width: '15px',
											height: '15px',
											border: '1px solid green',
											backgroundColor: 'rgba(0, 255, 0, 0.5)',
											marginRight: '5px',
											borderRadius: '20%'
										}}
									/>
									Incident
								</div>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<div 
										style={{
											width: '15px',
											height: '15px',
											border: '1px solid gray',
											backgroundColor: 'rgba(125, 125, 125, 0.5)',
											marginRight: '5px',
											borderRadius: '20%'
										}}
									/>
									Not Captured
								</div>
							</div>
						</div>
						<Skeleton variant="rectangular" width={"100%"} height={400} />
					</div>
					<div className={classes.wrapperCard}>
						<Skeleton variant="text" width={200} height={50} className={classes.skeletonCenter} />
						<Skeleton variant="text" width={80} height={40} className={classes.skeletonCenter} />
						<div className={classes.videosGrid}>
							{[...Array(4)].map((_, index) => (
								<div key={index} className={classes.video}>
									<div className={classes.skeletonLoader}>
										<Skeleton variant="rectangular" className={classes.videoLoader} />
									</div>
									<Skeleton variant="text" width={120} height={30} className={classes.videoSkeletonText} />
								</div>
							))}
						</div>
					</div>
				</div>
			}
		</>
	);
}

function labelFixer(label: string): string {
	return mapping[label];
}


const convertToFormattedTime = (label: string) => {
	try {
		let timestamp = label.split(" ")[1];
		timestamp = timestamp.substring(0, timestamp.length - 4);

		const [datePart, timePart] = timestamp.split('_');
    const [year, month, day] = datePart.split('-').map(Number);
    const [hour, minute, second] = timePart.split('-').map(Number);

    const date = new Date(year, month - 1, day, hour, minute, second);

    const options: Intl.DateTimeFormatOptions = {
			hour: '2-digit',
			minute: '2-digit',
			second: '2-digit',
			hour12: true
    };
    const timeObj = new Intl.DateTimeFormat('en-US', options).format(date);
		return (<><strong>Start Time:</strong> {timeObj}</>);
	} catch (e) {
		return label;
	}
}

function convertDate(date: string): string {
	try {
		const [dateString, timeString] = date.split(' ');
		const [year, month, day] = dateString.split('-');
		const [hourString, minuteString] = timeString.split(':');
		const hour = parseInt(hourString);

		return `${`${month}-${day}-${year}`} ${hour % 12 || 12}:${minuteString} ${hour >= 12 ? 'pm' : 'am'}`;
	} catch {
		return date;
	}
}