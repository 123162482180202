import { useNavigate } from "react-router-dom";
import { Theme } from "../../../Styling/Theme";
import { AppDispatch } from "../../../store";
import { BottomNavigation, BottomNavigationAction, Button, Dialog, DialogActions, DialogContent, DialogTitle, Paper } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartBar, faFile, faGear, faMoon, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { LOG_OUT } from "../../../Redux/Actions";
import { createUseStyles } from "react-jss";
import { useState } from "react";

const useStyles = createUseStyles((theme: Theme) => {
  return {
    sidebar: {
      border: '1px solid' + theme.sidebarBorder,
    },
    wrapper: {
      display: 'flex',
      height: '100vh',
      position: 'fixed',
      width: "fit-content",
      zIndex: 100,
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    menuDivider: {
      flex: 1,
      marginBottom: 32,
    },
    divider: {
			borderColor: theme.dividerBorder,
			backgroundColor: theme.dividerColor,
		},
    activeTab: {
      backgroundColor: theme.backgroundSecondary,
      color: theme.textPrimary,
    },
    spacing: {
      marginBottom: 10,
    },
    root: {
      width: 'fit-content',
    },
    mobileNavigation: {
      "@media (min-width: 769px)": {
        display: "none",
      },
    }
  }
})

interface MobileProps {
  setTheme: (darkMode: boolean) => void;
  darkMode: boolean;
  theme: Theme;
  dispatch: AppDispatch
	currentPath: string;
}


export const MobileNavBar: React.FC<MobileProps> = ({ setTheme, darkMode, dispatch, currentPath }): JSX.Element => {
	const classes = useStyles();
	const navigate = useNavigate();

	const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

	return (
		<>
		<Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 100 }} className={classes.mobileNavigation}>
			<BottomNavigation
				showLabels
				value={currentPath}
				onChange={(event, newValue) => {
					switch (newValue) {
						case "settings":
							handleClickOpen();
							break;
						default:
							navigate(newValue);
							break
					}
				}}
			>
				<BottomNavigationAction value={"/incidents"} label="Data Explorer" icon={<FontAwesomeIcon icon={faChartBar} color={darkMode ? "#8FC9F9" : "#1876D1"} />} />
				<BottomNavigationAction value={"/file-explorer"} label="File Explorer" icon={<FontAwesomeIcon icon={faFile} color={darkMode ? "#8FC9F9" : "#1876D1"} />} />
				<BottomNavigationAction value={"settings"} label="Settings" icon={<FontAwesomeIcon icon={faGear} color={darkMode ? "#8FC9F9" : "#1876D1"} />} />
			</BottomNavigation>
		</Paper>
		<Dialog
      open={open}
      onClose={handleClose}
    >
      <DialogTitle >Settings</DialogTitle>
      <DialogContent>
        <div>
          <Button
						onClick={() => {
							setTheme(!darkMode);
						}}
						sx={{ width: "100%" }}
						variant="contained"
						endIcon={<FontAwesomeIcon icon={faMoon} />}
					>
						{darkMode ? "Light Mode" : "Dark Mode"}
					</Button>
        </div>
        <div style={{ width: "70vw" }}>
					<Button
						onClick={() => {
							dispatch({ type: LOG_OUT, payload: null });
							navigate("/login");
						}}
						sx={{ width: "100%", marginTop: 1 }}
						variant="contained"
						endIcon={<FontAwesomeIcon icon={faSignOutAlt} />}
					>
						Sign Out
					</Button>
				</div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
		</>
	);
}
