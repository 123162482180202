import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Skeleton, Button } from '@mui/material';
import { getDataExplorer, getInLineOperationInProgress, getIncidents } from '../../../../Redux/Selectors';
import { useSelector } from 'react-redux';
import { DispatchProps } from '../../../../Models/Props';
import { SET_DATA_EXPLORER_ORDER_SORT } from '../../../../Redux/Actions';
import { createUseStyles } from 'react-jss';
import { Theme } from '../../../../Styling/Theme';
import { useNavigate } from 'react-router-dom';
import { IncidentInformation } from '../../../../Models/DataExplorer';

const headCells: {id: string, label: string}[] = [
  { id: 'id', label: 'Case ID' },
  { id: 'roadway', label: 'Roadway' },
  { id: 'startTime', label: 'Start Time' },
  { id: 'lastUpdated', label: 'Last Updated' },
  { id: 'county', label: 'County' },
  { id: 'region', label: 'Region' },
  { id: 'direction', label: 'Direction' },
  { id: 'description', label: 'Description' },
];

const useStyles = createUseStyles((theme: Theme) => {
	return {
		cellNoWrap: {
			whiteSpace: "nowrap",
		},
		cellDescription: {
			minWidth: '650px',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'pre-line',
			wordWrap: 'break-word',
		},
		skeletonCell: {
			marginLeft: 10,
		},
		scroll: {
			overflowX: 'scroll',
			'&::-webkit-scrollbar': {
				height: '6px',
				backgroundColor: theme.backgroundPrimary,
			},
			'&::-webkit-scrollbar-thumb': {
				backgroundColor: '#888',
				borderRadius: '6px',
			},
			"& td": {
				padding: '7px 15px',
			},
		}
	};
});

export const DataExplorerTable: React.FC<DispatchProps> = ({ dispatch }): JSX.Element => {
	const dataExplorer = useSelector(getDataExplorer);
	const incidents = useSelector(getIncidents);
	const inLineOperationInProgress = useSelector(getInLineOperationInProgress);

	const classes = useStyles();
	const navigate = useNavigate();

	const handleSort = (property: string) => () => {
		const isAsc = dataExplorer.sort === property && dataExplorer.order === 'asc';
		dispatch({ type: SET_DATA_EXPLORER_ORDER_SORT, payload: { order: isAsc ? 'desc' : 'asc', sort: property }});
  };

	type RenderDescriptionProps = {
			description: string;
	};

	function RenderDescription({ description }: RenderDescriptionProps) {
		if (description?.includes("<div class='cellSpacer'>")) {
			return <span dangerouslySetInnerHTML={{ __html: description }} />;
		}

		return <span>{description}</span>;
	}

	return (
		<TableContainer className={classes.scroll}>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell>
							Incident
						</TableCell>
						{headCells.map((headCell) => (
              <TableCell key={headCell.id}>
								<TableSortLabel
									active={dataExplorer.sort === headCell.id}
									direction={dataExplorer.order}
									onClick={handleSort(headCell.id)}
									className={classes.cellNoWrap}
								>
									{headCell.label}
								</TableSortLabel>
							</TableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody>
					{!inLineOperationInProgress && incidents.map((incidentItem: IncidentInformation) => (
						<TableRow key={incidentItem.id}>
							{/* <TableCell>
								<Checkbox checked={dataExplorer.selectedRows.includes(incidentItem.id)} onChange={() => handleSelect(incidentItem.id)} />
							</TableCell> */}
							<TableCell>
								<Button variant="contained" color="primary" onClick={() => navigate(`/incident?id=${incidentItem.id}`)}>View</Button>
							</TableCell>
							<TableCell className={classes.cellNoWrap}>{incidentItem.id}</TableCell>
							<TableCell className={classes.cellNoWrap}>{incidentItem.roadwayName}</TableCell>
							<TableCell className={classes.cellNoWrap}>{convertDate(incidentItem.startTime)}</TableCell>
							<TableCell className={classes.cellNoWrap}>{convertDate(incidentItem.lastUpdated)}</TableCell>
							<TableCell className={classes.cellNoWrap}>{incidentItem.county}</TableCell>
							<TableCell className={classes.cellNoWrap}>{incidentItem.region}</TableCell>
							<TableCell className={classes.cellNoWrap}>{incidentItem.direction}</TableCell>
							<TableCell className={classes.cellDescription}>
								<RenderDescription description={incidentItem.description} />
							</TableCell>
						</TableRow>
					))}
					{inLineOperationInProgress && Array(dataExplorer.limit).fill(0).map((_, index) => (
						<TableRow key={index}>
							<TableCell>
								<Skeleton className={classes.skeletonCell}  variant="rectangular" width={60} height={37} sx={{borderRadius: 1}}/>
							</TableCell>
							{headCells.slice(0, -1).map((headCell) => (
								<TableCell key={headCell.id}>
									<Skeleton className={classes.skeletonCell}  variant="rectangular" width={100} height={15} />
								</TableCell>
							))}
							<TableCell>
								<Skeleton className={classes.skeletonCell} variant="rectangular" width={500} height={40} />
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

function convertDate(date: string): string {
	try{
		const [dateString, timeString] = date.split(' ');
		const [year, month, day] = dateString.split('-');
		const [hourString, minuteString] = timeString.split(':');
		const hour = parseInt(hourString);
	
		return `${`${month}-${day}-${year}`} ${hour % 12 || 12}:${minuteString} ${hour >= 12 ? 'pm' : 'am'}`;
	} catch {
		return date;
	}
}