import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { faChevronCircleRight, faCircleChevronLeft, faUserSlash } from '@fortawesome/free-solid-svg-icons';
import { Button, FormControlLabel, Switch } from '@mui/material';
import { createUseStyles } from 'react-jss';
import { Theme } from '../../../Styling/Theme';
import { AppDispatch } from '../../../store';
import { LOG_OUT } from '../../../Redux/Actions';

const useStyles = createUseStyles((theme: Theme) => {
  return {
    sidebarFooter: {
      display: 'flex',
      justifyContent: 'center',
      paddingBottom: '20px',
      flexDirection: 'column',
      alignItems: 'center',
    },
    collapseButton: {
      width: '80%',
    },
    signOutButton: {
      width: '80%',
    },
  };
});

export const SidebarFooter: React.FC<{ setCollapsed: (collapse: boolean) => void, collapsed: boolean, setDarkMode: (darkMode: boolean) => void, darkMode: boolean, dispatch: AppDispatch }> = ({ setCollapsed, collapsed, setDarkMode, darkMode, dispatch }) => {
  const classes = useStyles();
  
  return (
    <div className={classes.sidebarFooter}>
      {!collapsed &&
        <FormControlLabel
          control={<Switch checked={darkMode} onChange={() => setDarkMode(!darkMode)} />}
          label="Dark Mode"
        />
      }
      {!collapsed &&
        <Button
          onClick={() => dispatch({ type: LOG_OUT, payload: null })}
          startIcon={<FontAwesomeIcon icon={faUserSlash} />}
          className={classes.signOutButton}
          variant="contained"
          sx={{ marginBottom: 0.8 }}
        >
          Sign Out
        </Button>
      }
      {!collapsed &&
        <Button
          onClick={() => setCollapsed(!collapsed)}
          startIcon={<FontAwesomeIcon icon={faCircleChevronLeft} />}
          className={classes.collapseButton}
          variant="contained"
        >
          Collapse
        </Button>
      }
      {collapsed &&
        <Button
          onClick={() => setCollapsed(!collapsed)}
          className={classes.collapseButton}
          variant="contained"
        >
          <FontAwesomeIcon icon={faChevronCircleRight} size="xl" />
        </Button>
      }
    </div>
  );
};