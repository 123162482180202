export const camerasIdsCapturing = [
  "1000--11",
  "1001--11",
  "1002--11",
  "1003--11",
  "1004--11",
  "1008--11",
  "1101--11",
  "1105--11",
  "1115--11",
  "1118--11",
  "1138--11",
  "1140--11",
  "1142--11",
  "1143--11",
  "1144--5",
  "1145--5",
  "1146--5",
  "1150--5",
  "1156--11",
  "1163--11",
  "1164--11",
  "1165--11",
  "1167--11",
  "1169--11",
  "1170--11",
  "1175--11",
  "1180--11",
  "1182--11",
  "1186--11",
  "1187--11",
  "1188--11",
  "1189--11",
  "1190--11",
  "1191--11",
  "1193--11",
  "1194--11",
  "1195--11",
  "1196--11",
  "1198--11",
  "1199--11",
  "1200--11",
  "1201--11",
  "1202--11",
  "1203--11",
  "1207--11",
  "1208--11",
  "1209--11",
  "1210--11",
  "1213--11",
  "1214--11",
  "1215--11",
  "1216--11",
  "1217--11",
  "1218--11",
  "1219--11",
  "1220--11",
  "1221--11",
  "1222--11",
  "1223--11",
  "1224--11",
  "1226--11",
  "1227--11",
  "1228--11",
  "1229--11",
  "1230--11",
  "1231--11",
  "1232--11",
  "1233--11",
  "1234--11",
  "1235--11",
  "1237--11",
  "1238--11",
  "1239--11",
  "1240--11",
  "1241--11",
  "1243--11",
  "1244--11",
  "1245--11",
  "1246--11",
  "1248--11",
  "1249--11",
  "1250--11",
  "1251--11",
  "1252--11",
  "1254--11",
  "1256--11",
  "1257--11",
  "1258--11",
  "1259--11",
  "1260--11",
  "1261--11",
  "1262--11",
  "1263--11",
  "1264--11",
  "1265--11",
  "1267--11",
  "1268--11",
  "1269--11",
  "1270--11",
  "1271--11",
  "1272--11",
  "1273--11",
  "1274--11",
  "1275--11",
  "1276--11",
  "1277--11",
  "1278--11",
  "1279--11",
  "1280--11",
  "1281--11",
  "1282--11",
  "1283--11",
  "1284--11",
  "1285--11",
  "1286--11",
  "1287--11",
  "1288--11",
  "1289--11",
  "1291--11",
  "1292--11",
  "1295--11",
  "1296--11",
  "1297--11",
  "1299--11",
  "1300--11",
  "1301--11",
  "1302--11",
  "1307--11",
  "1308--11",
  "1310--11",
  "1311--11",
  "1313--11",
  "1315--11",
  "1317--11",
  "1318--11",
  "1319--11",
  "1320--11",
  "1322--11",
  "1323--11",
  "1342--5",
  "1345--5",
  "1346--5",
  "1347--5",
  "1348--5",
  "1349--5",
  "1350--5",
  "1351--5",
  "1352--5",
  "1353--5",
  "1356--5",
  "1357--5",
  "1359--5",
  "1360--5",
  "1361--5",
  "1362--11",
  "1362--5",
  "1363--11",
  "1364--11",
  "1364--5",
  "1365--11",
  "1365--5",
  "1366--11",
  "1366--5",
  "1367--11",
  "1368--11",
  "1369--11",
  "1370--11",
  "1371--11",
  "1371--5",
  "1372--11",
  "1372--5",
  "1373--11",
  "1373--5",
  "1374--11",
  "1375--11",
  "1377--11",
  "1377--5",
  "1378--11",
  "1379--11",
  "1380--5",
  "1381--11",
  "1381--5",
  "1382--11",
  "1382--5",
  "1383--11",
  "1383--5",
  "1384--11",
  "1384--5",
  "1385--11",
  "1386--5",
  "1387--11",
  "1387--5",
  "1388--11",
  "1389--11",
  "1390--11",
  "1390--5",
  "1391--11",
  "1392--11",
  "1392--5",
  "1393--11",
  "1393--5",
  "1394--11",
  "1394--5",
  "1396--11",
  "1396--5",
  "1397--5",
  "1398--11",
  "1399--11",
  "1400--11",
  "1400--5",
  "1401--11",
  "1401--5",
  "1402--11",
  "1402--5",
  "1403--11",
  "1403--5",
  "1404--11",
  "1405--5",
  "1406--5",
  "1407--11",
  "1407--5",
  "1408--11",
  "1408--5",
  "1409--11",
  "1409--5",
  "1411--11",
  "1411--5",
  "1412--11",
  "1412--5",
  "1413--11",
  "1414--11",
  "1414--5",
  "1415--11",
  "1415--5",
  "1416--11",
  "1416--5",
  "1417--11",
  "1418--11",
  "1418--5",
  "1420--11",
  "1420--5",
  "1421--11",
  "1421--5",
  "1422--11",
  "1422--5",
  "1423--11",
  "1423--5",
  "1424--11",
  "1424--5",
  "1425--11",
  "1426--11",
  "1426--5",
  "1427--11",
  "1428--5",
  "1429--5",
  "1430--11",
  "1430--5",
  "1431--5",
  "1432--5",
  "1434--5",
  "1435--5",
  "1437--5",
  "1438--5",
  "1440--5",
  "1441--5",
  "1442--5",
  "1444--5",
  "1445--5",
  "1450--5",
  "1455--5",
  "1456--5",
  "1457--5",
  "1458--5",
  "1459--5",
  "1460--5",
  "1461--5",
  "1475--5",
  "1476--5",
  "1477--5",
  "1478--5",
  "1479--5",
  "1480--5",
  "1481--5",
  "1485--5",
  "1486--5",
  "1487--5",
  "1488--5",
  "1489--5",
  "1490--5",
  "1493--5",
  "1494--5",
  "1500--5",
  "1501--5",
  "1502--5",
  "1503--5",
  "1509--5",
  "1510--5",
  "1511--5",
  "1512--5",
  "1513--5",
  "1514--5",
  "1515--5",
  "1516--5",
  "1519--5",
  "1520--5",
  "1521--5",
  "1523--5",
  "1524--5",
  "1530--5",
  "1531--5",
  "1532--5",
  "1583--5",
  "1584--5",
  "1585--5",
  "1586--5",
  "1587--5",
  "1588--5",
  "1589--5",
  "1590--5",
  "1591--5",
  "1592--5",
  "1593--5",
  "1594--5",
  "1595--5",
  "1596--5",
  "1597--5",
  "1598--5",
  "1600--5",
  "1604--5",
  "1605--5",
  "1606--5",
  "1607--5",
  "1608--5",
  "1609--5",
  "1610--5",
  "1611--5",
  "1612--5",
  "1613--5",
  "1614--5",
  "1615--5",
  "1616--5",
  "1617--5",
  "1618--5",
  "1619--5",
  "1620--5",
  "1621--11",
  "1621--5",
  "1622--5",
  "1623--5",
  "1624--5",
  "1625--5",
  "1626--5",
  "1627--5",
  "1628--5",
  "1629--5",
  "1630--5",
  "1631--5",
  "1633--5",
  "1635--5",
  "1636--5",
  "1638--5",
  "1639--5",
  "1641--5",
  "1646--5",
  "1647--5",
  "1649--5",
  "1656--5",
  "1657--5",
  "1658--5",
  "1659--5",
  "1660--5",
  "1664--5",
  "1666--5",
  "1667--5",
  "1668--5",
  "1669--5",
  "1670--5",
  "1671--5",
  "1672--5",
  "1673--5",
  "1674--5",
  "1675--5",
  "1676--5",
  "1677--5",
  "1678--5",
  "1679--5",
  "1680--5",
  "1682--5",
  "1686--5",
  "1750--5",
  "1751--5",
  "1752--5",
  "1753--5",
  "1754--5",
  "1755--5",
  "1756--5",
  "1757--5",
  "1758--5",
  "1759--5",
  "1760--5",
  "1762--5",
  "1763--5",
  "1764--5",
  "1765--5",
  "1766--5",
  "1767--5",
  "1768--5",
  "1769--5",
  "1770--5",
  "1771--5",
  "1772--5",
  "1773--5",
  "1775--5",
  "1776--5",
  "1777--5",
  "1778--5",
  "1779--5",
  "1780--5",
  "1781--5",
  "1782--5",
  "1783--5",
  "1784--5",
  "1785--5",
  "1786--5",
  "1787--5",
  "1788--5",
  "1789--5",
  "1790--5",
  "1791--5",
  "1792--5",
  "1793--5",
  "1794--5",
  "1795--5",
  "1796--5",
  "1797--5",
  "1798--5",
  "1799--5",
  "1800--5",
  "1801--5",
  "1802--5",
  "1803--5",
  "1804--5",
  "1805--5",
  "1806--5",
  "1807--5",
  "1808--5",
  "1809--5",
  "1814--5",
  "1821--5",
  "1971--11",
  "1978--11",
  "1980--11",
  "1982--11",
  "1985--11",
  "1995--11",
  "1996--11",
  "1997--11",
  "2015--11",
  "2017--11",
  "2019--11",
  "2020--11",
  "2021--11",
  "2037--11",
  "2039--11",
  "2058--11",
  "2060--11",
  "2061--11",
  "2115--11",
  "2203--11",
  "2204--11",
  "2256--11",
  "2264--11",
  "2266--11",
  "2267--11",
  "2268--11",
  "2270--11",
  "2271--11",
  "2272--11",
  "2273--11",
  "2274--11",
  "2275--11",
  "2276--11",
  "2277--11",
  "2278--11",
  "2279--11",
  "2283--11",
  "2284--11",
  "2285--11",
  "2286--11",
  "2287--11",
  "2288--11",
  "2289--11",
  "2292--11",
  "2294--11",
  "2295--11",
  "2296--11",
  "2297--11",
  "2298--11",
  "2299--11",
  "2300--11",
  "2301--11",
  "2302--11",
  "2304--11",
  "2305--11",
  "2306--11",
  "2308--11",
  "2309--11",
  "2311--11",
  "2312--11",
  "2313--11",
  "2314--11",
  "2315--11",
  "2316--11",
  "2317--11",
  "2318--11",
  "2320--11",
  "2321--11",
  "2322--11",
  "2323--11",
  "2324--11",
  "2325--11",
  "2326--11",
  "2327--11",
  "2328--11",
  "2329--11",
  "2331--11",
  "2332--11",
  "2334--11",
  "2336--11",
  "2337--11",
  "2338--11",
  "2340--11",
  "2341--11",
  "2342--11",
  "2343--11",
  "2345--11",
  "2346--11",
  "2347--11",
  "2349--11",
  "2350--11",
  "2351--11",
  "2352--11",
  "2353--11",
  "2356--11",
  "2358--11",
  "2359--11",
  "2360--11",
  "2361--11",
  "2362--11",
  "2363--11",
  "2382--11",
  "2386--11",
  "2387--11",
  "2388--11",
  "2389--11",
  "2391--11",
  "2392--11",
  "2403--11",
  "2404--11",
  "2406--11",
  "2407--11",
  "2411--11",
  "2412--11",
  "2413--11",
  "2414--11",
  "2415--11",
  "2416--11",
  "2418--11",
  "2419--11",
  "2437--11",
  "2439--11",
  "2441--11",
  "2442--11",
  "2443--11",
  "2444--11",
  "2445--11",
  "2447--11",
  "2448--11",
  "2449--11",
  "2450--11",
  "2456--11",
  "2457--11",
  "2459--11",
  "2460--11",
  "2461--11",
  "2463--11",
  "2465--11",
  "2469--11",
  "2471--11",
  "2472--11",
  "2473--11",
  "2474--11",
  "2475--11",
  "2478--11",
  "2479--11",
  "2481--11",
  "2482--11",
  "2484--11",
  "2486--11",
  "2487--11",
  "2488--11",
  "2490--11",
  "2492--11",
  "2493--11",
  "2496--11",
  "2501--11",
  "2503--11",
  "2504--11",
  "2506--11",
  "2508--11",
  "2516--11",
  "2517--11",
  "2519--11",
  "2526--11",
  "2538--11",
  "2539--11",
  "2540--11",
  "2541--11",
  "2543--11",
  "2544--11",
  "2545--11",
  "2547--11",
  "2562--11",
  "2563--11",
  "2573--11",
  "2576--11",
  "2621--11",
  "2622--11",
  "2648--11",
  "2735--11",
  "2736--11",
  "2737--11",
  "2738--11",
  "2997--4",
  "2998--4",
  "2999--4",
  "3000--4",
  "3001--4",
  "3002--4",
  "3003--4",
  "3004--4",
  "3005--4",
  "3006--4",
  "3007--4",
  "3008--4",
  "3009--4",
  "3010--4",
  "3011--4",
  "3012--4",
  "3013--4",
  "3015--4",
  "3016--4",
  "3017--4",
  "3018--4",
  "3019--4",
  "3020--4",
  "3021--4",
  "3022--4",
  "3023--4",
  "3024--4",
  "3025--4",
  "3026--4",
  "3027--4",
  "3028--4",
  "3029--4",
  "3030--4",
  "3031--4",
  "3032--4",
  "3033--4",
  "3034--4",
  "3035--4",
  "3036--4",
  "3037--4",
  "3039--4",
  "3040--4",
  "3041--4",
  "3042--4",
  "3043--4",
  "3044--4",
  "3045--4",
  "3046--4",
  "3047--4",
  "3051--4",
  "3052--4",
  "3054--4",
  "3056--4",
  "3057--4",
  "3058--4",
  "3401--4",
  "3402--4",
  "3403--4",
  "3404--4",
  "3405--4",
  "3406--4",
  "3407--4",
  "3408--4",
  "3409--4",
  "3410--4",
  "3411--4",
  "3412--4",
  "3413--4",
  "3415--4",
  "3416--4",
  "3417--4",
  "3418--4",
  "3419--4",
  "3420--4",
  "3421--4",
  "3422--4",
  "3423--4",
  "3424--4",
  "3425--4",
  "3426--4",
  "3427--4",
  "3428--4",
  "3429--4",
  "3430--4",
  "3431--4",
  "3432--4",
  "3433--4",
  "3434--4",
  "3435--4",
  "3436--4",
  "3437--4",
  "3438--4",
  "3439--4",
  "3440--4",
  "3441--4",
  "3442--4",
  "3443--4",
  "3444--4",
  "3447--4",
  "3448--4",
  "3449--4",
  "3450--4",
  "3451--4",
  "3452--4",
  "3453--4",
  "3454--4",
  "3455--4",
  "3456--4",
  "3457--4",
  "3458--4",
  "3459--4",
  "3460--4",
  "3462--4",
  "3463--4",
  "3464--4",
  "3465--4",
  "3466--4",
  "3470--4",
  "3471--4",
  "3474--4",
  "3482--4",
  "3483--4",
  "3484--4",
  "3485--4",
  "3486--4",
  "3487--4",
  "3488--4",
  "3489--4",
  "3490--4",
  "3491--4",
  "3492--4",
  "3493--4",
  "3495--4",
  "3496--4",
  "3497--4",
  "3498--4",
  "3499--4",
  "3500--4",
  "3501--4",
  "3502--4",
  "3503--4",
  "3505--4",
  "3506--4",
  "3507--4",
  "3508--4",
  "3509--4",
  "3510--4",
  "3511--4",
  "3512--4",
  "3513--4",
  "3514--4",
  "3515--4",
  "3516--4",
  "3517--4",
  "3518--4",
  "3519--4",
  "3520--4",
  "3521--4",
  "3522--4",
  "3523--4",
  "3524--4",
  "3525--4",
  "3526--4",
  "3527--4",
  "3529--4",
  "3530--4",
  "3531--4",
  "3532--4",
  "3533--4",
  "3534--4",
  "3535--4",
  "3536--4",
  "3537--4",
  "3538--4",
  "3539--4",
  "3540--4",
  "3541--4",
  "3559--4",
  "3578--4",
  "3579--4",
  "3580--4",
  "3589--4",
  "3596--4",
  "3604--4",
  "3631--4",
  "3659--4",
  "3684--4",
  "3691--4",
  "3718--4",
  "3723--4",
  "3724--4",
  "3726--4",
  "3732--4",
  "3733--4",
  "3734--4",
  "3735--4",
  "3736--4",
  "3737--4",
  "3738--4",
  "3739--4",
  "3744--4",
  "3748--4",
  "3749--4",
  "3750--4",
  "3751--4",
  "3752--4",
  "3753--4",
  "3754--4",
  "3755--4",
  "3756--4",
  "3757--4",
  "3758--4",
  "3759--4",
  "3781--4",
  "3782--4",
  "3783--4",
  "3784--4",
  "3785--4",
  "3786--4",
  "3787--4",
  "3796--4",
  "3827--4",
  "3828--4",
  "3829--4",
  "3830--4",
  "3831--4",
  "3832--4",
  "3833--4",
  "3837--4",
  "3892--4",
  "3893--4",
  "3903--4",
  "3905--4",
  "3933--4",
  "3934--4",
  "3950--4",
  "3951--4",
  "3952--4",
  "3953--4",
  "3954--4",
  "3955--4",
  "3956--4",
  "3957--4",
  "3958--4",
  "3959--4",
  "3960--4",
  "3961--4",
  "3962--4",
  "3963--4",
  "3964--4",
  "3965--4",
  "3966--4",
  "3967--4",
  "3968--4",
  "3969--4",
  "403--11",
  "406--11",
  "407--11",
  "505--11",
  "515--11",
  "516--11",
  "543--11",
  "562--11",
  "570--9",
  "572--9",
  "588--11",
  "591--11",
  "592--11",
  "593--11",
  "594--11",
  "595--11",
  "597--11",
  "598--11",
  "599--11",
  "601--11",
  "602--11",
  "603--11",
  "604--11",
  "605--11",
  "606--11",
  "607--11",
  "608--11",
  "631--11",
  "634--11",
  "635--11",
  "636--11",
  "638--11",
  "639--11",
  "640--11",
  "641--11",
  "642--11",
  "643--11",
  "644--11",
  "645--11",
  "647--11",
  "648--11",
  "649--11",
  "650--11",
  "651--11",
  "652--11",
  "653--11",
  "654--11",
  "655--11",
  "656--11",
  "657--11",
  "658--11",
  "659--11",
  "662--11",
  "663--11",
  "664--11",
  "665--11",
  "666--11",
  "667--11",
  "668--11",
  "669--11",
  "670--11",
  "671--11",
  "672--11",
  "673--11",
  "674--11",
  "675--9",
  "676--11",
  "676--9",
  "677--11",
  "677--9",
  "678--9",
  "679--11",
  "680--11",
  "681--11",
  "682--11",
  "683--11",
  "687--11",
  "688--11",
  "691--11",
  "692--11",
  "693--11",
  "701--11",
  "702--11",
  "824--12",
  "861--11",
  "862--11",
  "865--11",
  "867--11",
  "868--11",
  "869--11",
  "870--11",
  "876--11",
  "877--11",
  "878--11",
  "884--11",
  "891--11",
  "895--11",
  "906--11",
  "907--11",
  "909--11",
  "910--11",
  "911--11",
  "912--11",
  "913--11",
  "923--11",
  "924--11",
  "932--11",
  "933--11",
  "949--11",
  "950--11",
  "951--11",
  "952--11",
  "953--11",
  "954--11",
  "955--11",
  "959--12",
  "960--12",
  "961--11",
  "964--11",
  "965--11",
  "968--11",
  "969--11",
  "970--11",
  "971--11",
  "972--11",
  "985--11",
];