import React, { useEffect } from "react";
import { DispatchProps } from "../../../Models/Props";
import { createUseStyles } from "react-jss";
import { Theme } from "../../../Styling/Theme";
import { GET_INCIDENTS } from "../../../Redux/Actions";
import { DataExplorerTable } from "./ChildComponents/DataExplorerTable";
import { Pagination } from "./ChildComponents/Pagination";
import { getDataExplorer } from "../../../Redux/Selectors";
import { useSelector } from "react-redux";
import { Options } from "./ChildComponents/Options";
import { Divider, Paper } from "@mui/material";

const useStyles = createUseStyles((theme: Theme) => {
	return {
		wrapper: {
			display: "flex",
			flexDirection: "column",
			height: "100%",
			overflowX: "hidden",
		},
		wrapperCard: {
			borderRadius: 10,
			marginBottom: 10,
			margin: "10px 20px",
			"@media (max-width: 600px)": {
				margin: "10px 10px",
			},
		},
		dividerMobile: {
			"@media (min-width: 460px)": {
				display: "none",
			},
		},
		dividerDesktop: {
			"@media (max-width: 460px)": {
				display: "none",
			},
		},
	}
});

export const IncidentsFL511: React.FC<DispatchProps> = ({ dispatch }): JSX.Element => {
	const classes = useStyles();
	const dataExplorer = useSelector(getDataExplorer);

	useEffect(() => {
		dispatch({ type: GET_INCIDENTS, payload: null });
	}, [dispatch, dataExplorer.page, dataExplorer.sort, dataExplorer.order, dataExplorer.id, dataExplorer.description, dataExplorer.county, dataExplorer.roadwayName, dataExplorer.direction, dataExplorer.dateRange, dataExplorer.date, dataExplorer.severity, dataExplorer.limit]);

	return (
		<div className={classes.wrapper}>
			<div className={classes.wrapperCard}>
				<Paper>
					<Options
						dispatch={dispatch}
					/>
					<Divider sx={{ marginTop: 2 }} className={classes.dividerMobile} />
					<Divider sx={{ marginTop: 8 }} className={classes.dividerDesktop} />
					<DataExplorerTable
						dispatch={dispatch}
					/>
				</Paper>
				<Pagination
					dispatch={dispatch}
				/>
			</div>
		</div>
	);
}
