import { DataExplorer } from "../Models/DataExplorer";
import { Folders } from "../Models/Folder";
import { IncidentInformation, IncidentVideos } from "../Models/Incident";
import { requestApi } from "./Fetch";
import { formatDate } from "./Helpers";

export const getFilesAPI = async ( token: string, month?: string, year?: string ): Promise<Folders> => {
  const req = {
    month: month,
    year: year,
  };

  return await requestApi(`file_manager`, "POST", req, token, {});
};

export const getIncidentAPI = async ( token: string, incidentID: string ): Promise<{videos: IncidentVideos, incidentInformation: IncidentInformation }> => {
  const req = {
    incidentID: incidentID,
  }

  return await requestApi(`incident`, "POST", req, token, {});
}

export const getIncidentAdditionalAPI = async ( token: string, cameraIds: string[], coordinates: {longitude: number, latitude: number}, targetDate: string, dateBuffer: number, coordinateBuffer: number) => {
  const req = {
    cameraIds,
    coordinates,
    targetDate,
    dateBuffer,
    coordinateBuffer,
  }

  return await requestApi(`incidentAdditional`, "POST", req, token, {});
}

export const getIncidentsAPI = async ( token: string, dataExplorer: DataExplorer ) => {
  let resultDate = "";

  if (dataExplorer.date) {  
    let correctedDate = new Date(dataExplorer.date);
    resultDate = formatDate(correctedDate);
  } else if (dataExplorer.dateRange && dataExplorer.dateRange[0] && dataExplorer.dateRange[1]) {
    const correctedDate1 = new Date(dataExplorer.dateRange[0]);
    const correctedDate2 = new Date(dataExplorer.dateRange[1]);

    const resultDate1 = formatDate(correctedDate1);
    const resultDate2 = formatDate(correctedDate2);

    resultDate = resultDate1 + "," + resultDate2;
  }

  const req = {
    id: dataExplorer.id,
    roadwayName: dataExplorer.roadwayName,
    startTime: resultDate,
    county: dataExplorer.county,
    severity: dataExplorer.severity,
    direction: dataExplorer.direction,
    description: dataExplorer.description,
    limit: dataExplorer.limit,
    offset: dataExplorer.offset,
    sort: dataExplorer.sort,
    order: dataExplorer.order,
  }

  return await requestApi(`incidents`, "POST", req, token, {});
}

export const getIncidentRestoreAPI = async ( token: string, incidentID: string ) => {
  const req = {
    incidentID: incidentID,
  }

  return await requestApi(`restoreIncident`, "POST", req, token, {});
}
